import React, { useState } from 'react';
import { buttons } from '@/components';

export const Threshold = ({ onChange, onDelete, threshold }) => {
    const [state, setState] = useState(threshold);
    const handleOnChange = (evt) =>
        setState({ ...state, [evt.target.name]: evt.target.value });
    const handleOnBlur = () => onChange(state);
    return (
        <div className="w-full flex space-x-2 text-gray-900">
            <input
                name="lowerBound"
                type="number"
                step="1"
                value={state.lowerBound}
                className="px-2 flex-1 rounded-md h-9 min-w-9"
                onBlur={handleOnBlur}
                onChange={handleOnChange}
            />
            <input
                name="upperBoundNotEqual"
                type="number"
                step="1"
                value={state.upperBoundNotEqual}
                className="px-2 flex-1 rounded-md h-9 min-w-9"
                onBlur={handleOnBlur}
                onChange={handleOnChange}
            />
            <buttons.Delete onDelete={onDelete} text="" />
        </div>
    );
};
