import React, { useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { v4 as uuidv4 } from 'uuid';
import * as buttons from './buttons';
import {
    ruleMetricOptions,
    staffTypeOptions,
    rewardTypeOptions,
} from '@/utils';
import {
    getDatesFromPeriod,
    getPeriodFromDates,
    existsPeriod,
} from '../pages/bonusRuleGeneral/common';
import { customSelectDropdown } from './style';

const getRuleDefinition = (rows, selectedRuleIds) => {
    var selectedRule = selectedRuleIds
        ? rows.filter((item) => item.original.id === selectedRuleIds[0])
        : null;

    selectedRule = selectedRule && selectedRule[0]?.original;

    return selectedRule
        ? {
              masterName: selectedRule.masterName.value,
              operatingChain: selectedRule.operatingChain.value,
              staffType: selectedRule.staffType.value,
              ruleType: selectedRule.ruleType.value,
              ruleValue: selectedRule.ruleValue.value,
              ruleMetric: selectedRule.ruleMetric.value,
              rewardType: selectedRule.rewardType.value,
              fiscalKey: selectedRule.fiscalKey.value,
              startDate: selectedRule.startDate.value,
              endDate: selectedRule.endDate.value,
              ruleId: selectedRule.ruleId,
          }
        : {};
};

const isSameRuleDefinition = (ruleOne, ruleTwo) =>
    ruleOne.original.operatingChain.value === ruleTwo.operatingChain &&
    ruleOne.original.staffType.value === ruleTwo.staffType &&
    ruleOne.original.ruleType.value === ruleTwo.ruleType &&
    ruleOne.original.ruleValue.value === ruleTwo.ruleValue &&
    ruleOne.original.ruleMetric.value === ruleTwo.ruleMetric &&
    ruleOne.original.rewardType.value === ruleTwo.rewardType;

const getRuleValues = (rows, ruleDefinition) =>
    Object.keys(ruleDefinition).length > 0
        ? rows
              .filter((row) =>
                  row.original.ruleId
                      ? row.original.ruleId === ruleDefinition.ruleId
                      : // ruleId doesn`t exists (= new unsaved rule selected)
                        isSameRuleDefinition(row, ruleDefinition)
              )
              .reduce(
                  (acc, item) =>
                      (acc = [
                          ...acc,
                          {
                              limit: item.original.limit.value,
                              rewardValue: item.original.rewardValue.value,
                          },
                      ]),
                  []
              )
        : [{ limit: '', rewardValue: '' }];

// Map options to single string, values divided by commas, remove duplicates
const mapMultiselectOptionsToString = (options) =>
    options
        .map((item) => item.value.toLowerCase())
        .filter((value, index, self) => self.indexOf(value) === index)
        .join(',');

// Map string of values divided by commas to array of options
const mapStringToMultiselectOptions = (value) =>
    value.split(',').map((item) => ({ value: item, label: item }));


const mapKeyValueArgsToRuleTypes = (keyValueArgs, ruleType) => {
    // If ruletype is BRANDCODE then return getBrands, for CATEGORYCODE return getCategories etc.
    switch (ruleType) {
        case 'BRANDCODE':
            return keyValueArgs.getBrands;
        case 'CATEGORYCODE':
            return keyValueArgs.getCategories;
        case 'MODELCODE':
            return keyValueArgs.getModels;
        case 'GROUPCODE':
            return keyValueArgs.getGroups;
        case 'ARTICLECODE':
            return keyValueArgs.getArticles;
    }
};

const InputContainer = ({
    children,
    inputName,
    inputLabel,
    error,
    onPaste = () => {},
}) => {
    return (
        <div>
            <div className="w-full flex flex-row" onPaste={onPaste}>
                <label
                    className="text-gray-50 w-48"
                    alt="required"
                    htmlFor={inputName}
                >
                    {inputLabel}
                </label>
                {children}
            </div>
            {error && (
                <div className="mt-2">
                    {error[inputName].errMsg && (
                        <span className="ml-36 text-red-500">
                            {error[inputName].errMsg === true
                                ? `Please select ${inputName}.`
                                : error[inputName].errMsg}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

const CopyRuleForm = ({
    rows,
    onCopy,
    onClose,
    operatingChainOptions,
    ruleTypes,
    keyValueArgs,
    fiscalKeyOptions,
    newRule,
}) => {
    const selectedRuleIds = newRule
        ? undefined
        : rows
              .filter((item) => item.original.selected)
              .map((item) => item.original.id);

    const ruleDefinition = getRuleDefinition(rows, selectedRuleIds);
    const ruleValues = getRuleValues(rows, ruleDefinition);

    const initialOperatingChain = operatingChainOptions.filter(
        (i) => i.value === ruleDefinition.operatingChain
    )[0];
    const initialRuleType = ruleTypes.filter(
        (i) => i.value === ruleDefinition.ruleType
    )[0];
    const initialRuleMetric = ruleMetricOptions.filter(
        (i) => i.value === ruleDefinition.ruleMetric
    )[0];
    const initialStaffType = staffTypeOptions.filter(
        (i) => i.value === ruleDefinition.staffType
    )[0];
    const initialRewardType = rewardTypeOptions.filter(
        (i) => i.value === ruleDefinition.rewardType
    )[0];

    const ruleTypeLookupMap = {
        MODELTYPECODE: keyValueArgs.getModelTypes,
        CATEGORYCODE: keyValueArgs.getCategories,
        ARTICLECODE: keyValueArgs.getArticles,
        MODELCODE: keyValueArgs.getModels,
        BRANDCODE: keyValueArgs.getBrands,
        GROUPCODE: keyValueArgs.getGroups,
    };

    const ruleValueInitialOptionsGetter =
        ruleTypeLookupMap[initialRuleType?.value] || (() => []);
    const ruleValueInitialOptions = ruleValueInitialOptionsGetter();
    const initialRuleValue = [
        'CATEGORYCODE',
        'BRANDCODE',
        'MODELCODE',
        'GROUPCODE',
    ].includes(initialRuleType?.value)
        ? mapStringToMultiselectOptions(ruleDefinition.ruleValue) || ''
        : initialRuleType?.value === 'ARTICLECODE'
          ? ruleDefinition.ruleValue?.replaceAll(',', '\n') || ''
          : Array.isArray(ruleValueInitialOptions) &&
          ruleValueInitialOptions.filter(
              (i) => i.value === ruleDefinition.ruleValue
          )[0];

    const [ruleValueOptions, setRuleValueOptions] = useState(
        initialRuleValue || []
    );

    const RULE_TYPES_WO_RULE_VALUE = ['OUTLET', 'B2B', 'KITCHEN'];

    const [isRuleValueMandatory, setIsRuleValueMandatory] = useState(
        initialRuleType
            ? !RULE_TYPES_WO_RULE_VALUE.includes(initialRuleType?.value)
            : false
    );

    const INPUT_NAMES = {
        MASTER_NAME: 'masterName',
        OPERATING_CHAIN: 'operatingChain',
        STAFF_TYPE: 'staffType',
        RULE_TYPE: 'ruleType',
        RULE_VALUE: 'ruleValue',
        RULE_METRIC: 'ruleMetric',
        LIMIT_AND_VALUE: 'limitAndValue',
        LIMIT: 'limit',
        REWARD_VALUE: 'rewardValue',
        REWARD_TYPE: 'rewardType',
        PERIOD: 'fiscalKey',
        START_DATE: 'startDate',
        END_DATE: 'endDate',
    };

    const [form, setForm] = useState({
        [INPUT_NAMES.MASTER_NAME]: ruleDefinition.masterName || '',
        [INPUT_NAMES.OPERATING_CHAIN]: initialOperatingChain,
        [INPUT_NAMES.STAFF_TYPE]: initialStaffType,
        [INPUT_NAMES.RULE_TYPE]: initialRuleType,
        [INPUT_NAMES.RULE_VALUE]: initialRuleValue || '',
        [INPUT_NAMES.RULE_METRIC]: initialRuleMetric,
        [INPUT_NAMES.LIMIT_AND_VALUE]: ruleValues,
        [INPUT_NAMES.REWARD_TYPE]: initialRewardType,
        [INPUT_NAMES.PERIOD]: ruleDefinition.fiscalKey || '',
        [INPUT_NAMES.START_DATE]: ruleDefinition.startDate || '',
        [INPUT_NAMES.END_DATE]: ruleDefinition.endDate || '',
    });

    const [error, setError] = useState({
        [INPUT_NAMES.MASTER_NAME]: { isReq: false, errMsg: '' },
        [INPUT_NAMES.OPERATING_CHAIN]: { isReq: true, errMsg: '' },
        [INPUT_NAMES.STAFF_TYPE]: { isReq: true, errMsg: '' },
        [INPUT_NAMES.RULE_TYPE]: { isReq: true, errMsg: '' },
        [INPUT_NAMES.RULE_VALUE]: { isReq: false, errMsg: '' },
        [INPUT_NAMES.RULE_METRIC]: { isReq: true, errMsg: '' },
        [INPUT_NAMES.LIMIT_AND_VALUE]: { isReq: true, errMsg: '' },
        [INPUT_NAMES.REWARD_TYPE]: { isReq: true, errMsg: '' },
        [INPUT_NAMES.PERIOD]: { isReq: false, errMsg: '' },
        [INPUT_NAMES.START_DATE]: { isReq: false, errMsg: '' },
        [INPUT_NAMES.END_DATE]: { isReq: false, errMsg: '' },
    });

    const [formError, setFormError] = useState('');

    const isAsync = (ruleType) =>
        [
            'ARTICLECODE',
            'MODELCODE',
            'BRANDCODE',
            'GROUPCODE',
            'CATEGORYCODE',
        ].includes(ruleType?.value);

    const isMulti = (ruleType) =>
        ['MODELCODE', 'BRANDCODE', 'GROUPCODE', 'CATEGORYCODE'].includes(
            ruleType?.value
        );

    const validateLimitAndValue = (name, index) => {
        // Skip item with given name and index (= item validated right now in onChange handler)
        const otherFieldName =
            name === INPUT_NAMES.LIMIT
                ? INPUT_NAMES.REWARD_VALUE
                : INPUT_NAMES.LIMIT;
        const invalidValue = form.limitAndValue.find(
            (item, i) =>
                ((!item[name] || item[name] === '0') && i !== index) ||
                !item[otherFieldName] ||
                item[otherFieldName] === '0'
        );
        return invalidValue ? true : false;
    };

    const onValidate = (value, name) => {
        setError((prev) => ({
            ...prev,
            [name]: { ...prev[name], errMsg: value },
        }));
    };

    const validateForm = (inputName) => {
        let isValid = true;
        setFormError('');
        const errorObj = inputName ? error[inputName] : error;

        Object.keys(errorObj).forEach((key) => {
            const errObj = error[key];
            if (errObj.errMsg) {
                isValid = false;
            } else if (errObj.isReq && key === INPUT_NAMES.LIMIT_AND_VALUE) {
                const invalidValue = form[key].find(
                    (item) =>
                        !item.limit ||
                        item.limit === '0' ||
                        !item.rewardValue ||
                        item.rewardValue === '0'
                );
                if (invalidValue && Object.keys(invalidValue).length > 0) {
                    isValid = false;
                    onValidate(true, key);
                }
            } else if (
                key === INPUT_NAMES.PERIOD &&
                form[key] &&
                form[key !== '']
            ) {
                isValid = existsPeriod(form[key], fiscalKeyOptions);
                onValidate(true, key);
            } else if (
                key === INPUT_NAMES.RULE_VALUE &&
                !RULE_TYPES_WO_RULE_VALUE.includes(
                    form[INPUT_NAMES.RULE_TYPE]?.value
                )
            ) {
                isValid = form[key] && form[key] !== '';
                if (!isValid) onValidate(true, key);
                if (isValid && isMulti(form[INPUT_NAMES.RULE_TYPE])) {
                    // Multiselect component - parse the array of options to one string with values divided by commmas
                    const parsedCodes = parseRuleValue(
                        mapMultiselectOptionsToString(form.ruleValue)
                    );

                    const codes = parsedCodes.split(',');
                    if (form[INPUT_NAMES.RULE_TYPE]?.value === 'BRANDCODE') {
                        isValid = codes.every((code) => {
                            return keyValueArgs
                                .getBrands(code)
                                .some(
                                    (option) =>
                                        option.id &&
                                        option.value.toLowerCase() ===
                                            code.toLowerCase()
                                );
                        });
                    } else {
                        isValid = codes.every((code) => {
                            return mapKeyValueArgsToRuleTypes(
                                keyValueArgs,
                                form[INPUT_NAMES.RULE_TYPE]?.value
                            )(code).some(
                                (option) =>
                                    option.value.toLowerCase() ===
                                    code.toLowerCase()
                            );
                        });
                    }

                    if (!isValid)
                        onValidate(
                            'Some of the brand codes does not exist',
                            key
                        );
                }
            } else if (errObj.isReq && !form[key]) {
                if (
                    !(
                        key === INPUT_NAMES.RULE_VALUE &&
                        RULE_TYPES_WO_RULE_VALUE.includes(
                            form[INPUT_NAMES.RULE_TYPE]?.value
                        )
                    )
                ) {
                    isValid = false;
                    onValidate(true, key);
                }
            }
        });

        return isValid;
    };

    const handleChange = (e, name, index = undefined) => {
        let value = '';
        if (index >= 0) {
            const limitAndValue = form[INPUT_NAMES.LIMIT_AND_VALUE];
            value = e.target.value;
            limitAndValue[index][name] = value;
            setForm((prev) => ({
                ...prev,
                limitAndValue,
            }));
        } else if (
            [
                INPUT_NAMES.PERIOD,
                INPUT_NAMES.START_DATE,
                INPUT_NAMES.END_DATE,
                INPUT_NAMES.MASTER_NAME,
            ].includes(name)
        ) {
            value = e.target.value;
            setForm((prev) => ({
                ...prev,
                [name]: value,
            }));
        } else if (
            name === INPUT_NAMES.RULE_VALUE &&
            form[INPUT_NAMES.RULE_TYPE]?.value === 'ARTICLECODE'
        ) {
            value = e.target.value;
            setForm((prev) => ({
                ...prev,
                [name]: value ? value?.replace(',', '') : '',
            }));
        } else {
            value = e;
            if (
                name === INPUT_NAMES.RULE_VALUE &&
                isMulti(form[INPUT_NAMES.RULE_TYPE])
            ) {
                // If one pastes multiple values single string into the form field, divide it into individual options for the Multiselect component
                const options = [];
                if (form[INPUT_NAMES.RULE_TYPE]?.value === 'BRANDCODE') {
                    value.forEach((item) => {
                        const values = item.value.split(',');
                        values.forEach((brand) => {
                            options.push({
                                value: brand,
                                label: brand,
                                id: item.id,
                            });
                        });
                    });
                } else {
                    value.forEach((item) => {
                        const values = item.value.split(',');
                        values.forEach((value) => {
                            options.push({
                                value: value.split('-')[0],
                                label: value,
                            });
                        });
                    });
                }

                value = options;
            }

            setForm((prev) => ({
                ...prev,
                [name]: value,
            }));
        }

        // Reload ruleValue options after ruleType change
        if (name === INPUT_NAMES.RULE_TYPE) {
            loadOptions(null, e.value);
            setForm((prev) => ({
                ...prev,
                [INPUT_NAMES.RULE_VALUE]: '',
            }));

            setIsRuleValueMandatory(
                !RULE_TYPES_WO_RULE_VALUE.includes(e.value)
            );
            setError((prev) => ({
                ...prev,
                [name]: { ...prev[name], isReq: isRuleValueMandatory },
            }));
        }

        // Revalidate form field after change
        let errMessage = '';
        let errFieldName = name;

        if (name === INPUT_NAMES.PERIOD) {
            const pattern = /^\d{6}$/;
            const month = Number(value.substring(4));
            let isValid =
                value === '' ||
                (pattern.test(value) && month > 0 && month <= 12);
            errMessage = isValid ? false : 'Period format is MMYYYY';

            if (isValid) {
                isValid = value === '' || existsPeriod(value, fiscalKeyOptions);
                errMessage = isValid ? false : 'Period does not exists';
            }

            let periodDates;
            // Set startDate and endDate according to period (or clear them, if period is empty or invalid)
            if (isValid) {
                periodDates = getDatesFromPeriod(value, fiscalKeyOptions);
            }
            setForm((prev) => ({
                ...prev,
                [INPUT_NAMES.START_DATE]: periodDates
                    ? periodDates.startDate
                    : '',
                [INPUT_NAMES.END_DATE]: periodDates ? periodDates.endDate : '',
            }));
        } else if (name === INPUT_NAMES.RULE_VALUE) {
            const isValid = value && value !== '';
            errMessage = isValid ? false : 'Rule value is mandatory';
        } else if (
            [INPUT_NAMES.START_DATE, INPUT_NAMES.END_DATE].includes(name)
        ) {
            // Set period accoring to start end endDate (if there is a match) or clear it
            const startDate =
                name === INPUT_NAMES.START_DATE
                    ? value
                    : form[INPUT_NAMES.START_DATE];
            const endDate =
                name === INPUT_NAMES.END_DATE
                    ? value
                    : form[INPUT_NAMES.END_DATE];
            const period = getPeriodFromDates(
                startDate,
                endDate,
                fiscalKeyOptions
            );
            setForm((prev) => ({
                ...prev,
                [INPUT_NAMES.PERIOD]:
                    period && period.isPeriodMatch ? period.fiscalKey : '',
            }));
        } else if (
            name === INPUT_NAMES.LIMIT ||
            name === INPUT_NAMES.REWARD_VALUE
        ) {
            errFieldName = INPUT_NAMES.LIMIT_AND_VALUE;
            errMessage =
                !value ||
                value === '' ||
                value === '0' ||
                validateLimitAndValue(name, index);
        } else if (
            name === INPUT_NAMES.RULE_TYPE &&
            RULE_TYPES_WO_RULE_VALUE.includes(value.value)
        ) {
            // Revalidate ruleValue field if ruleType without rule value is selected
            onValidate('', INPUT_NAMES.RULE_VALUE);
        } else {
            errMessage = error[name].isReq && (!value || value === '');
        }

        onValidate(errMessage, errFieldName);
    };

    const handleAddClick = (e) => {
        const limitAndValue = form[INPUT_NAMES.LIMIT_AND_VALUE];
        limitAndValue.push({
            [INPUT_NAMES.LIMIT]: '',
            [INPUT_NAMES.REWARD_VALUE]: '',
        });
        setForm((prev) => ({
            ...prev,
            limitAndValue,
        }));
        e.preventDefault();
    };

    const handleDeleteClick = (e, i) => {
        let limitAndValue = form[INPUT_NAMES.LIMIT_AND_VALUE];
        limitAndValue = limitAndValue.filter((item, index) => index !== i);
        setForm((prev) => ({
            ...prev,
            limitAndValue,
        }));
        e.preventDefault();
    };

    const parseRuleValue = (value) => {
        // Replace new lines with commas
        let parsedValue = value.replaceAll('\n', ',');
        // Replace repeating commas (empty lines in textArea)
        parsedValue = parsedValue.replaceAll(/,+/g, ',');
        // Remove traling commas (empty lines at the end)
        parsedValue = parsedValue.replace(/,+$/, '');

        return parsedValue;
    };

    const handleCopySubmit = (e) => {
        e.preventDefault();

        // Validate individual form fields
        var isValid = validateForm();

        // Validate rule
        if (isValid) {
            const limits = form[INPUT_NAMES.LIMIT_AND_VALUE].map(
                (item) => item.limit
            );
            const duplicateLimits = limits.filter(
                (item, index) => limits.indexOf(item) !== index
            );
            isValid = duplicateLimits.length > 0 ? false : true;
            if (!isValid) setFormError('Duplicate limit');
        }

        if (isValid) {
            isValid =
                (form[INPUT_NAMES.FISCAL_KEY] &&
                    form[INPUT_NAMES.FISCAL_KEY] !== '') ||
                (form[INPUT_NAMES.START_DATE] &&
                    form[INPUT_NAMES.START_DATE] !== '' &&
                    form[INPUT_NAMES.END_DATE] &&
                    form[INPUT_NAMES.END_DATE] !== '');
            if (!isValid)
                setFormError('Period or Start date + End date must be filled');
        }

        if (isValid) {
            let rules = [];

            const ruleValueParsed = [
                'ARTICLECODE',
                'CATEGORYCODE',
                'BRANDCODE',
                'MODELCODE',
                'GROUPCODE',
            ].includes(form.ruleType.value)
                ? parseRuleValue(
                      isMulti(form.ruleType)
                          ? // Multiselect component - need to parse the array of options into a single string, values divided by commma
                            mapMultiselectOptionsToString(form.ruleValue)
                          : form.ruleValue
                  )
                : form.ruleValue?.value;

            form.limitAndValue
                .filter(
                    (item) =>
                        item.rewardValue &&
                        item.rewardValue !== '' &&
                        item.limit &&
                        item.limit !== ''
                )
                .map((item) => {
                    const id = uuidv4();
                    rules = [
                        ...rules,
                        {
                            id,
                            altId: id,
                            masterName: form.masterName,
                            operatingChain: form.operatingChain.value,
                            staffType: form.staffType.value,
                            ruleType: form.ruleType.value,
                            ruleValue: ruleValueParsed,
                            ruleMetric: form.ruleMetric.value,
                            limit: item.limit,
                            rewardValue: item.rewardValue,
                            rewardType: form.rewardType.value,
                            fiscalKey: form.fiscalKey,
                            startDate: form.startDate,
                            endDate: form.endDate,
                            // Remove existing rowSpan value to not break the view of the newly added data
                            rowSpan: 1,
                            ruleRowsNumber: 1,
                        },
                    ];
                });

            onCopy(rules);
            onClose();
        }
    };

    const loadOptions = async (inputValue, ruleType = undefined) => {
        let options = [];
        const selectedRuleType =
            ruleType || form[INPUT_NAMES.RULE_TYPE].value || '';

        if (
            ['MODELCODE', 'BRANDCODE', 'GROUPCODE'].includes(selectedRuleType)
        ) {
            const searchValue =
                inputValue || form[INPUT_NAMES.RULE_VALUE]?.value;
            const optionsGetter = ruleTypeLookupMap[selectedRuleType];

            options = optionsGetter ? await optionsGetter(searchValue) : [];
        } else {
            const optionsGetter = ruleTypeLookupMap[selectedRuleType];
            options = optionsGetter ? optionsGetter() : [];
        }

        setRuleValueOptions(options);
        return options;
    };

    const renderRuleValueSelect = (isAsync, isMulti) =>
        isAsync(form[INPUT_NAMES.RULE_TYPE]) ? (
            <AsyncSelect
                key={form[INPUT_NAMES.RULE_TYPE]?.value}
                id={INPUT_NAMES.RULE_VALUE}
                className="w-full inline-block text-gray-900"
                classNamePrefix=""
                styles={customSelectDropdown()}
                onChange={(e) => handleChange(e, INPUT_NAMES.RULE_VALUE)}
                loadOptions={(value) => loadOptions(value, undefined)}
                value={form[INPUT_NAMES.RULE_VALUE]}
                isDisabled={ruleValueOptions.length === 0}
                defaultOptions
                isMulti={isMulti(form[INPUT_NAMES.RULE_TYPE])}
            />
        ) : (
            <Select
                key={form[INPUT_NAMES.RULE_TYPE]?.value}
                id={INPUT_NAMES.RULE_VALUE}
                className="w-full inline-block text-gray-900"
                styles={customSelectDropdown(32)}
                options={
                    Array.isArray(ruleValueOptions)
                        ? ruleValueOptions
                        : ruleValueInitialOptions
                }
                onChange={(e) => handleChange(e, INPUT_NAMES.RULE_VALUE)}
                value={form[INPUT_NAMES.RULE_VALUE]}
                isDisabled={ruleValueOptions.length === 0}
            />
        );

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('Text');
        // Replace new lines with commas, remove spaces
        let parsedData = pastedData.replaceAll('\n', ',').replaceAll(' ', '');
        // Remove all trailing commas
        parsedData = parsedData.replace(/,+$/, '');
        const changeEvent = [{ value: parsedData }];
        handleChange(changeEvent, INPUT_NAMES.RULE_VALUE);
    };

    // Copy rule values to clipboard, map the multiselect options to a single string, values divided by commas
    const copyRuleValues = (e) => {
        e.preventDefault();

        let ruleValuesString = '';
        if (form[INPUT_NAMES.RULE_VALUE]) {
            form[INPUT_NAMES.RULE_VALUE].forEach((item) => {
                ruleValuesString += item.value + ',';
            });

            navigator.clipboard.writeText(ruleValuesString.slice(0, -1));
        }
    };

    return (
        <div className="bg-gray-700 p-6 rounded-lg">
            <form
                id="copyRuleForm"
                className="flex flex-col space-y-2 w-full"
                onSubmit={handleCopySubmit}
            >
                <h2 className="font-bold text-center h-10 text-xl text-gray-50">
                    {`${newRule ? 'Create' : 'Copy'} rule`}
                </h2>

                <InputContainer
                    inputName={INPUT_NAMES.MASTER_NAME}
                    inputLabel={'Master name'}
                    error={error}
                >
                    <input
                        id={INPUT_NAMES.MASTER_NAME}
                        className="h-10 w-full p-2"
                        value={form[INPUT_NAMES.MASTER_NAME]}
                        onChange={(e) =>
                            handleChange(e, INPUT_NAMES.MASTER_NAME)
                        }
                    />
                </InputContainer>
                <InputContainer
                    inputName={INPUT_NAMES.OPERATING_CHAIN}
                    inputLabel={'Operating chain *'}
                    error={error}
                >
                    <Select
                        id={INPUT_NAMES.OPERATING_CHAIN}
                        options={operatingChainOptions}
                        onChange={(e) =>
                            handleChange(e, INPUT_NAMES.OPERATING_CHAIN)
                        }
                        className="w-full inline-block text-gray-900"
                        classNamePrefix="select-sm"
                        styles={customSelectDropdown(32)}
                        value={form[INPUT_NAMES.OPERATING_CHAIN]}
                    />
                </InputContainer>
                <InputContainer
                    inputName={INPUT_NAMES.STAFF_TYPE}
                    inputLabel={'Staff type *'}
                    error={error}
                >
                    <Select
                        id={INPUT_NAMES.STAFF_TYPE}
                        options={staffTypeOptions}
                        onChange={(e) =>
                            handleChange(e, INPUT_NAMES.STAFF_TYPE)
                        }
                        className="w-full inline-block text-gray-900"
                        classNamePrefix="select-sm"
                        styles={customSelectDropdown(32)}
                        value={form[INPUT_NAMES.STAFF_TYPE]}
                    />
                </InputContainer>
                <InputContainer
                    inputName={INPUT_NAMES.RULE_TYPE}
                    inputLabel={'Rule type *'}
                    error={error}
                >
                    <Select
                        id={INPUT_NAMES.RULE_TYPE}
                        options={ruleTypes}
                        onChange={(e) => handleChange(e, INPUT_NAMES.RULE_TYPE)}
                        className="w-full inline-block text-gray-900"
                        classNamePrefix="select-sm"
                        styles={customSelectDropdown(32)}
                        value={form[INPUT_NAMES.RULE_TYPE]}
                    />
                </InputContainer>
                <InputContainer
                    inputName={INPUT_NAMES.RULE_VALUE}
                    inputLabel={`Rule value ${isRuleValueMandatory ? '*' : ''}`}
                    error={error}
                    onPaste={
                        isMulti(form[INPUT_NAMES.RULE_TYPE])
                            ? handlePaste
                            : () => {}
                    }
                >
                    {form[INPUT_NAMES.RULE_TYPE]?.value === 'ARTICLECODE' ? (
                        <textarea
                            className="w-full h-28 p-2 border-gray-500 rounded"
                            onChange={(e) =>
                                handleChange(e, INPUT_NAMES.RULE_VALUE)
                            }
                            value={form[INPUT_NAMES.RULE_VALUE]}
                            onKeyDown={stop}
                            onKeyUp={stop}
                        />
                    ) : (
                        renderRuleValueSelect(isAsync, isMulti)
                    )}
                </InputContainer>
                <InputContainer
                    inputName={INPUT_NAMES.RULE_METRIC}
                    inputLabel={'Rule metric *'}
                    error={error}
                >
                    <Select
                        id={INPUT_NAMES.RULE_METRIC}
                        options={ruleMetricOptions}
                        onChange={(e) =>
                            handleChange(e, INPUT_NAMES.RULE_METRIC)
                        }
                        className="w-full inline-block text-gray-900"
                        classNamePrefix="select-sm"
                        styles={customSelectDropdown(32)}
                        value={form[INPUT_NAMES.RULE_METRIC]}
                    />
                </InputContainer>
                {form.limitAndValue.map((item, i) => (
                    <div key={i} className="w-full flex flex-row">
                        <label className="text-gray-50 w-40" alt="required">
                            Limit & Value *
                        </label>
                        <input
                            className="w-28 h-10 mr-8 p-2 text-right"
                            value={form[INPUT_NAMES.LIMIT_AND_VALUE][i].limit}
                            onChange={(e) =>
                                handleChange(e, INPUT_NAMES.LIMIT, i)
                            }
                            type="number"
                        />
                        <input
                            className="w-28 h-10 mr-8 p-2 text-right"
                            value={
                                form[INPUT_NAMES.LIMIT_AND_VALUE][i].rewardValue
                            }
                            onChange={(e) =>
                                handleChange(e, INPUT_NAMES.REWARD_VALUE, i)
                            }
                            type="number"
                        />
                        <buttons.Delete
                            onDelete={(e) => handleDeleteClick(e, i)}
                            text=""
                        />
                    </div>
                ))}
                {error && (
                    <div className="mt-2">
                        {form.limitAndValue.length > 0 &&
                            error[INPUT_NAMES.LIMIT_AND_VALUE].errMsg && (
                                <span className="ml-36 text-red-500">
                                    {error[INPUT_NAMES.LIMIT_AND_VALUE]
                                        .errMsg === true
                                        ? `Limit and value must be > 0.`
                                        : error[INPUT_NAMES.LIMIT_AND_VALUE]
                                              .errMsg}
                                </span>
                            )}
                    </div>
                )}
                <div className="w-full flex flex-row justify-end pb-2">
                    <buttons.Add onAdd={handleAddClick} text={'Add Limit'} />
                </div>
                <InputContainer
                    inputName={INPUT_NAMES.REWARD_TYPE}
                    inputLabel={'Reward type *'}
                    error={error}
                >
                    <Select
                        id={INPUT_NAMES.REWARD_TYPE}
                        className="w-full inline-block text-gray-900"
                        options={rewardTypeOptions}
                        onChange={(e) =>
                            handleChange(e, INPUT_NAMES.REWARD_TYPE)
                        }
                        classNamePrefix="select-sm"
                        styles={customSelectDropdown(32)}
                        value={form[INPUT_NAMES.REWARD_TYPE]}
                    />
                </InputContainer>
                <InputContainer
                    inputName={INPUT_NAMES.PERIOD}
                    inputLabel={'Period'}
                    error={error}
                >
                    <input
                        id={INPUT_NAMES.PERIOD}
                        className="h-10 w-full p-2 text-right"
                        value={form[INPUT_NAMES.PERIOD]}
                        onChange={(e) => handleChange(e, INPUT_NAMES.PERIOD)}
                    />
                </InputContainer>
                <InputContainer
                    inputName={INPUT_NAMES.START_DATE}
                    inputLabel={'Start date'}
                    error={error}
                >
                    <input
                        id={INPUT_NAMES.START_DATE}
                        className="h-10 w-full p-2 text-right"
                        value={form[INPUT_NAMES.START_DATE]}
                        onChange={(e) =>
                            handleChange(e, INPUT_NAMES.START_DATE)
                        }
                        type="date"
                    />
                </InputContainer>
                <InputContainer
                    inputName={INPUT_NAMES.END_DATE}
                    inputLabel={'End date'}
                    error={error}
                >
                    <input
                        id={INPUT_NAMES.END_DATE}
                        className="h-10 w-full p-2 text-right"
                        value={form[INPUT_NAMES.END_DATE]}
                        onChange={(e) => handleChange(e, INPUT_NAMES.END_DATE)}
                        type="date"
                    />
                </InputContainer>
                <div className="text-red-500">{formError}</div>
                <div className="flex w-full">
                    {form[INPUT_NAMES.RULE_TYPE] &&
                        isMulti(form[INPUT_NAMES.RULE_TYPE]) && (
                            <div>
                                <buttons.Copy
                                    text="Copy rule value"
                                    onClick={copyRuleValues}
                                />
                            </div>
                        )}
                    <div className="ml-auto space-x-2">
                        <buttons.Cancel onCancel={onClose} />
                        <buttons.Copy
                            text={newRule ? 'Create' : 'Copy'}
                            type="submit"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CopyRuleForm;
