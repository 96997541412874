import axios from 'axios';
import { simulationIdState } from '@/simulationIdState';
import { getEnvVar } from '@/hostedEnvironmentResolver';

export const axiosUserContext = {};

const ax = axios.create({
    baseURL: window.location.origin + getEnvVar("REACT_APP_API_PATH"),
    headers: {
        'Content-type': 'application/json',
    },
});

ax.interceptors.request.use(async (config) => {
    const token = axiosUserContext.accessToken;
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
});

ax.interceptors.response.use(
    (response) => {
        // If access is regained after timeout, reset the 'refreshAuth' flag
        if (window.location.hash.indexOf('refreshed') > -1) {
            if (response.status === 200) {
                window.location.hash = '';
            }
        }

        return response;
    },
    (error) => {
        // If unauthorized, reload page to refresh auth or trigger login
        if (error.response.status === 401) {
            if (window.location.hash.indexOf('refreshed') === -1) {
                window.location = window.location + '#refreshed';
                window.location.reload();
            }
        }

        return Promise.reject(error);
    }
);

const apiBaseUrl = '/v1';

function memoize(func) {
    const cache = {};

    return (ax, path) => async (force) => {
        const simulationIdUpdated =
            cache[path] &&
            cache[path].simulationId &&
            cache[path].simulationId !==
                simulationIdState.get()[0].simulationId;

        if (!cache[path] || force || simulationIdUpdated) {
            const result = await func(ax, path);
            result.data.simulationId = simulationIdState.get()[0].simulationId;
            cache[path] = result.data;
        }
        return cache[path];
    };
}

async function makeReq(ax, path) {
    const url = `${apiBaseUrl}/${path}`;
    const result = await ax(url);
    return result;
}

async function makeReqWithId(ax, path) {
    const url = `${apiBaseUrl}/${path}?simId=${
        simulationIdState.get()[0].simulationId
    }`;
    const result = await ax(url);
    return result;
}

const memoizedMakeReq = memoize(makeReq);
const memoizedMakeReqwithId = memoize(makeReqWithId);

async function getData(path, getValues, setValues, force) {
    const values = getValues();
    const url = `${apiBaseUrl}/${path}`;
    if (force || values.length === 0) {
        const result = await axios(url);
        setValues(result.data);
    }
    return getValues();
}

const postRequest = (ax, path) => async (data) => {
    const url = `${apiBaseUrl}/${path}`;
    return await ax.post(url, data);
};

const postRequestWFormData = (ax, path) => async (data) => {
    const url = `${apiBaseUrl}/${path}`;
    return await ax.post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export {
    getData,
    makeReq,
    memoizedMakeReq,
    memoizedMakeReqwithId,
    postRequest,
    postRequestWFormData,
};
