/* eslint-disable react/display-name */
import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';
import * as BiIcons from 'react-icons/bi';
import * as ImIcons from 'react-icons/im';
import { roles } from '@/utils';

export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        icon: (className) => <FaIcons.FaHome className={className} />,
        authRoles: [roles.ADMIN, roles.SIMULATION],
    },
    {
        title: 'Bonus Rules',
        path: '/bonus-rules',
        icon: (className) => <FaIcons.FaClipboardList className={className} />,
        authRoles: [roles.ADMIN, roles.SIMULATION],
        subNav: [
            // {
            //     title: 'General',
            //     path: '/bonus-rules/general',
            // },
            {
                title: 'Period specific',
                path: '/bonus-rules/period-specific',
            },
            {
                title: 'Date specific',
                path: '/bonus-rules/date-specific',
            },
            {
                title: 'Stacked bonus',
                path: '/bonus-rules/stacked-bonus',
            },
        ],
    },
    // {
    //     title: 'Store Specific Rules',
    //     path: '/store-specific-rules',
    //     icon: (className) => <IoIcons.IoStorefrontSharp className={className} />,
    //     subNav: [
    //         {
    //             title: 'General',
    //             path: '/store-specific-rules/general',
    //         },
    //         {
    //             title: 'Period specific',
    //             path: '/store-specific-rules/period-specific',
    //         }
    //     ]
    // },
    {
        title: 'Employee Factors',
        path: '/employee-factors',
        icon: (className) => (
            <BsIcons.BsPersonLinesFill className={className} />
        ),
        authRoles: [roles.ADMIN, roles.SIMULATION, roles.HR],
    },
    {
        title: 'Employee Correction',
        path: '/employee-corrections',
        icon: (className) => <FaIcons.FaUserEdit className={className} />,
        authRoles: [roles.ADMIN, roles.SIMULATION],
    },
    {
        title: 'Payout Systems',
        path: '/payout-systems',
        icon: (className) => <FaIcons.FaDollarSign className={className} />,
        authRoles: [roles.ADMIN, roles.SIMULATION],
        // subNav: [
        //     {
        //         title: 'General',
        //         path: '/payout-systems/general',
        //     },
        //     {
        //         title: 'Period specific',
        //         path: '/payout-systems/period-specific',
        //     }
        // ]
    },
    {
        title: 'NPS',
        path: '/nps',
        icon: (className) => <FaIcons.FaRegSmile className={className} />,
        authRoles: [roles.ADMIN, roles.SIMULATION],
        subNav: [
            {
                title: 'Stores',
                path: '/nps/stores',
            },
            {
                title: 'Employees',
                path: '/nps/employees',
            },
        ],
        // subNav: [
        //     {
        //         title: 'General',
        //         path: '/happy-or-not/general',
        //     },
        //     {
        //         title: 'Period specific',
        //         path: '/happy-or-not/period-specific',
        //     }
        // ]
    },
    {
        title: 'Operations Targets',
        path: '/operations-targets',
        icon: (className) => <BiIcons.BiTargetLock className={className} />,
        authRoles: [roles.ADMIN, roles.SIMULATION],
        // subNav: [
        //     {
        //         title: 'General',
        //         path: '/operations-targets/general',
        //     },
        //     {
        //         title: 'Period specific',
        //         path: '/operations-targets/period-specific',
        //     }
        // ]
    },
    {
        title: 'Settings',
        path: '/settings',
        icon: (className) => <ImIcons.ImCogs className={className} />,
        authRoles: [roles.ADMIN, roles.SIMULATION],
        subNav: [
            {
                title: 'Operating chains',
                path: '/settings/operating-chains',
            },
            {
                title: 'Stores',
                path: '/settings/stores',
            },
            {
                title: 'Model types',
                path: '/settings/model-types',
            },
            {
                title: 'Negative transactions',
                path: '/settings/negative-transactions',
            },
        ],
    },
    {
        title: 'Simulation Tool',
        path: '/simulation',
        icon: (className) => (
            <BsIcons.BsPersonLinesFill className={className} />
        ),
        authRoles: [roles.ADMIN, roles.SIMULATION],
    },
    {
        title: 'Competition',
        path: '/competition',
        icon: (className) => <BsIcons.BsAwardFill className={className} />,
        authRoles: [roles.ADMIN],
    },
];
