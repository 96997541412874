/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react';
import { useDependencies } from '@/DependencyProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import {
    columnTypes,
    filters,
    removeRow,
    removeAllRows,
} from '@/components/Table/index';
import { Table } from '@/components';
import {
    prepareTableDataMerge,
    prepareTableData,
} from '../bonusRuleGeneral/common';
import { simulationIdState } from '@/simulationIdState';
import { validators } from '@/utils';
import Loader from '@/components/Loader';

const parseStringToBoolean = (str) => {
    if (typeof str !== 'string') {
        throw new TypeError('Input must be a string');
    }

    const normalizedStr = str.trim().toLowerCase();

    if (normalizedStr === 'true' || normalizedStr === '1') {
        return true;
    } else if (normalizedStr === 'false' || normalizedStr === '0') {
        return false;
    } else {
        throw new Error(`Cannot parse string to boolean: ${str}`);
    }
};

const createRow = (npsEmployeeEntry, operatingChainOptions) => {
    const row = {
        id: npsEmployeeEntry.id,
        operatingChain: columnTypes.operatingChain.cell(
            npsEmployeeEntry.operatingChain,
            operatingChainOptions
        ),
        activeInBonusCalc: columnTypes.activeInBonusCalc.cell(
            npsEmployeeEntry.activeInBonusCalc
        ),
        activeInMC: columnTypes.activeInMc.cell(npsEmployeeEntry.activeInMC),
        minVotes: columnTypes.minVotes.cell(npsEmployeeEntry.minVotes),
        targetLevel1: columnTypes.targetLevel1.cell(
            npsEmployeeEntry.levels[0].target
        ),
        bonusLevel1: columnTypes.bonusLevel1.cell(
            npsEmployeeEntry.levels[0].bonusPctIP
        ),
        targetLevel2: columnTypes.target.cell(
            npsEmployeeEntry.levels[1]?.target
        ),
        bonusLevel2: columnTypes.rewardValue.cell(
            npsEmployeeEntry.levels[1]?.bonusPctIP
        ),
        targetLevel3: columnTypes.target.cell(
            npsEmployeeEntry.levels[2]?.target
        ),
        bonusLevel3: columnTypes.rewardValue.cell(
            npsEmployeeEntry.levels[2]?.bonusPctIP
        ),
        targetLevel4: columnTypes.target.cell(
            npsEmployeeEntry.levels[3]?.target
        ),
        bonusLevel4: columnTypes.rewardValue.cell(
            npsEmployeeEntry.levels[3]?.bonusPctIP
        ),
        mcMultiplier: columnTypes.mcMultiplier.cell(
            npsEmployeeEntry.mcMultiplier
        ),
    };

    return row;
};

const useQuery = () => new URLSearchParams(useLocation().search);

const Filter = (props) => {
    return (
        <>
            <filters.OperatingChainFilter {...props} />
        </>
    );
};

const NpsEmployees = () => {
    const { apiFactory } = useDependencies();

    const [npsEmployees, setNpsEmployees] = useState([]);
    const [periodOptions, setPeriodOptions] = useState([]);
    const [operatingChainOptions, setOperatingChainOptions] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [filterSpec, setFilterSpec] = useState({
        operatingChain: { label: 'All' },
    });

    const navigate = useNavigate();
    const query = useQuery();
    const fiscalKeyQueryParam = query.get('fiscalKey') || '';
    const { targetsApi, bonusSettingsApi, masterdataApi } = apiFactory;

    const selectedFiscalKey = useMemo(() => {
        return (
            periodOptions
                .flatMap((o) => o.options)
                .find((fk) => fk.value === fiscalKeyQueryParam) ?? { value: '' }
        );
    }, [periodOptions, fiscalKeyQueryParam]);

    const columns = useMemo(() => {
        const fixedColumns = [
            columnTypes.operatingChain.column(),
            columnTypes.activeInBonusCalc.column(),
            columnTypes.activeInMc.column(),
            columnTypes.minVotes.column('border-r-gray-300 border-r-4'),
            columnTypes.targetLevel1.column(),
            columnTypes.bonusLevel1.column('border-r-gray-300 border-r-4'),
            columnTypes.targetLevel2.column(),
            columnTypes.bonusLevel2.column('border-r-gray-300 border-r-4'),
            columnTypes.targetLevel3.column(),
            columnTypes.bonusLevel3.column('border-r-gray-300 border-r-4'),
            columnTypes.targetLevel4.column(),
            columnTypes.bonusLevel4.column('border-r-gray-300 border-r-4'),
            columnTypes.mcMultiplier.column(),
        ];
        return fixedColumns;
    }, []);

    const csvColumns = columns.map((c) => c.Header);

    useEffect(() => {
        const data = npsEmployees
            .filter((r) => r.fiscalKey === selectedFiscalKey.value)
            .flatMap((r) => r.data);

        const tableData = data.map((d) => createRow(d, operatingChainOptions));
        setTableData(tableData);
        setIsLoaded(true);
    }, [npsEmployees, selectedFiscalKey, operatingChainOptions]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const [periodOptions, npsEmployeeData, operatingChains] =
                await Promise.all([
                    masterdataApi.getBonusPeriods({ includeEmpty: true }),
                    targetsApi.getNpsEmployees(),
                    bonusSettingsApi.getOperatingChains(),
                ]);

            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));

            if (mounted) {
                setNpsEmployees(npsEmployeeData);
                setOperatingChainOptions(operatingChainOptions);
                setPeriodOptions(periodOptions);
            }
        }

        getData();
        return () => (mounted = false);
    }, [targetsApi, bonusSettingsApi, masterdataApi]);

    const rows = useMemo(() => {
        const filter = filters.composeFilters([filters.operatingChain]);
        const filtered = tableData.filter(filter(filterSpec));
        return [...filtered];
    }, [filterSpec, tableData]);

    const addRow = () => {
        const newRow = {
            id: uuidv4(),
            operatingChain: operatingChainOptions[0],
            minVotes: 0,
            activeInBonusCalc: false,
            activeInMC: false,
            levels: [
                {
                    target: 0,
                    bonusPctIP: 0,
                },
            ],
            mcMultiplier: 0,
        };

        setTableData([...tableData, createRow(newRow, operatingChainOptions)]);
    };

    const saveData = async () => {
        const transform = (tableData) => {
            const data = tableData.map((row) => {
                return {
                    id: row.id,
                    operatingChain: row.operatingChain.value,
                    activeInBonusCalc: columnTypes.activeInBonusCalc.value(row),
                    activeInMC: columnTypes.activeInMc.value(row),
                    minVotes: parseInt(columnTypes.minVotes.value(row)),
                    levels: [
                        {
                            target: parseInt(
                                columnTypes.targetLevel1.value(row)
                            ),
                            bonusPctIP: parseFloat(
                                columnTypes.bonusLevel1.value(row)
                            ),
                        },
                        {
                            target: parseInt(
                                columnTypes.targetLevel2.value(row)
                            ),
                            bonusPctIP: parseFloat(
                                columnTypes.bonusLevel2.value(row)
                            ),
                        },
                        {
                            target: parseInt(
                                columnTypes.targetLevel3.value(row)
                            ),
                            bonusPctIP: parseFloat(
                                columnTypes.bonusLevel3.value(row)
                            ),
                        },
                        {
                            target: parseInt(
                                columnTypes.targetLevel4.value(row)
                            ),
                            bonusPctIP: parseFloat(
                                columnTypes.bonusLevel4.value(row)
                            ),
                        },
                    ],
                    mcMultiplier: parseInt(columnTypes.mcMultiplier.value(row)),
                };
            });

            // Filter out Levels where both level and bonus are 0
            const filteredData = data.map((row) => {
                const levels = row.levels.filter(
                    (l) => l.target !== 0 || l.bonusPctIP !== 0
                );
                return { ...row, levels: levels };
            });
            return {
                data: filteredData,
                fiscalKey: selectedFiscalKey.value,
                simulationId: simulationIdState.get()[0].simulationId,
            };
        };

        const data = transform(tableData);

        // Check mandatory fields (operatingChain, minVotes, mcMultiplier)
        const invalidRows = data.data.filter(
            (d) =>
                d.operatingChain === '' ||
                d.minVotes === 0 ||
                d.mcMultiplier === 0
        );

        if (invalidRows.length > 0) {
            return {
                valid: false,
                message:
                    'Operating chain, Minimum and MC multiplier are mandatory',
            };
        }

        // Check operating chains for duplicates
        const operatingChains = data.data.map((d) => d.operatingChain);
        const duplicates = operatingChains.filter(
            (oc, index) => operatingChains.indexOf(oc) !== index
        );

        if (duplicates.length > 0) {
            return {
                valid: false,
                message: 'Operating chains must be unique',
            };
        }

        // Check the levels for all operating chains
        const invalidLevels = data.data.filter((d) => d.levels.length === 0);

        if (invalidLevels.length > 0) {
            return {
                valid: false,
                message: 'At least one target and bonus level pair is required',
            };
        }

        await targetsApi.saveNpsEmployees(data);
        const updatedData = await targetsApi.getNpsEmployees(true);
        setNpsEmployees(updatedData);
        return {
            valid: true,
        };
    };

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row[columnName] = { ...row[columnName], ...{ value } };
        setTableData(newTableData);
    };

    const copyFiscalKey = (fiscalKeyData, operatingChainData) => {
        // If no period selected, set default to All/General
        !fiscalKeyData ? (fiscalKeyData = { label: 'General', value: '' }) : '';
        const chosenOpChains = operatingChainData.map((oc) => oc.value);

        const newChosenObjects = prepareTableData(
            npsEmployees,
            selectedFiscalKey.value
        ); // Table we are copying INTO
        const newCopiedObjects = prepareTableData(
            npsEmployees,
            fiscalKeyData.value
        ); // Data we are copying

        let filteredNewCopiedObjects = [];
        if (operatingChainData[0]) {
            const newCopiedObjectsRows = newCopiedObjects.map((obj) =>
                createRow(obj, operatingChainOptions)
            );
            const newCopiedObjectsRowsFilteredByOpChain =
                newCopiedObjectsRows.filter((row) =>
                    chosenOpChains.includes(row.operatingChain.value)
                );
            filteredNewCopiedObjects =
                newCopiedObjectsRowsFilteredByOpChain.map((row) => {
                    return {
                        id: uuidv4(),
                        operatingChain: row.operatingChain.value,
                        activeInBonusCalc: row.activeInBonusCalc.value,
                        activeInMC: row.activeInMC.value,
                        minVotes: row.minVotes.value,
                        levels: [
                            {
                                target: row.targetLevel1.value,
                                bonusPctIP: row.bonusLevel1.value,
                            },
                            {
                                target: row.targetLevel2.value,
                                bonusPctIP: row.bonusLevel2.value,
                            },
                            {
                                target: row.targetLevel3.value,
                                bonusPctIP: row.bonusLevel3.value,
                            },
                            {
                                target: row.targetLevel4.value,
                                bonusPctIP: row.bonusLevel4.value,
                            },
                        ],
                        mcMultiplier: row.mcMultiplier.value,
                    };
                });
        } else {
            filteredNewCopiedObjects = newCopiedObjects;
        }

        const mergedPeriods = prepareTableDataMerge(
            newChosenObjects,
            filteredNewCopiedObjects
        );

        const tableData = mergedPeriods.map((mp) =>
            createRow(mp, operatingChainOptions)
        );
        setTableData(tableData);
    };

    const addCsvData = (rows) => {
        const newRows = rows.data.map((row) => ({
            id: uuidv4(),
            operatingChain: row.items[0],
            activeInBonusCalc: parseStringToBoolean(row.items[1]),
            activeInMC: parseStringToBoolean(row.items[2]),
            minVotes: row.items[3],
            levels: [
                { target: row.items[4], bonusPctIP: row.items[5] },
                { target: row.items[6], bonusPctIP: row.items[7] },
                { target: row.items[8], bonusPctIP: row.items[9] },
                { target: row.items[10], bonusPctIP: row.items[11] },
            ],
            mcMultiplier: row.items[12],
        }));

        const updatedData = newRows.map((row) =>
            createRow(row, operatingChainOptions)
        );

        const newTableData = [...updatedData, ...tableData];
        setTableData(newTableData);
    };

    const periodChanged = (selectedValue) => {
        navigate({
            search: `?fiscalKey=${selectedValue.value}`,
        });
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));
    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData, filterSpec.operatingChain));
    };

    const sampleCSV =
        () => `OCNOELK\ttrue\tfalse\t2\t10\t1\t20\t2\t30\t3\t40\t4\t5
OCSEELG\tfalse\ttrue\t2\t40\t4\t50\t5\t60\t6\t70\t7\t3
    `;

    // Convert table data to CSV string
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) =>
                    `${row.operatingChain.value}\t${
                        row.activeInBonusCalc.value
                    }\t${row.activeInMC.value}\t${row.minVotes.value}\t${
                        row.targetLevel1.value
                    }\t${row.bonusLevel1.value}\t${
                        row.targetLevel2.value ?? ''
                    }\t${row.bonusLevel2.value ?? ''}\t${
                        row.targetLevel3.value ?? ''
                    }\t${row.bonusLevel3.value ?? ''}\t${
                        row.targetLevel4.value ?? ''
                    }\t${row.bonusLevel4.value ?? ''}\t${
                        row.mcMultiplier.value
                    }`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    const existsValidator = (message, currentData) => async (row) => {
        const operatingChain = row[0];
        const exists =
            currentData.findIndex(
                (d) => d.operatingChain.value === operatingChain
            ) >= 0;
        return {
            isValid: exists ? false : true,
            text: exists ? message : '',
        };
    };

    const booleanValueValidator = (message, position) => async (row) => {
        const value = row[position];
        const isValid = ['true', 'false', '1', '0'].includes(value);
        return {
            isValid: isValid,
            text: isValid ? '' : message,
        };
    };

    const positiveNumberValidator = (message, position) => async (row) => {
        const value = row[position];
        const isValid = !isNaN(value) && value >= 0;
        return {
            isValid: isValid,
            text: isValid ? '' : message,
        };
    };

    const { optionValidator, composeValidators } = validators;

    const csvRowValidator =
        (currentData, operatingChainOptions) => async (row) => {
            const validators = [
                optionValidator(
                    'Invalid operating chain',
                    operatingChainOptions,
                    0
                ),
                existsValidator('Operating chain already exists', currentData),
                booleanValueValidator('Invalid active in bonus value', 1),
                booleanValueValidator('Invalid active in MC value', 2),
                positiveNumberValidator('Invalid min votes value', 3),
                positiveNumberValidator('Invalid target level 1 value', 4),
                positiveNumberValidator('Invalid bonus level 1 value', 5),
                positiveNumberValidator('Invalid target level 2 value', 6),
                positiveNumberValidator('Invalid bonus level 2 value', 7),
                positiveNumberValidator('Invalid target level 3 value', 8),
                positiveNumberValidator('Invalid bonus level 3 value', 9),
                positiveNumberValidator('Invalid target level 3 value', 10),
                positiveNumberValidator('Invalid bonus level 3 value', 11),
                positiveNumberValidator('Invalid MC multiplier value', 12),
            ];
            const validator = composeValidators(validators);
            return await validator(row);
        };

    return (
        <div className="flex w-full h-full flex-col">
            <div className="text-center py-8 w-full">
                <label className="font-bold">Period: </label>
                <Select
                    options={periodOptions}
                    onChange={periodChanged}
                    className="w-48 inline-block text-gray-900"
                    value={selectedFiscalKey}
                />
            </div>
            <div className="content w-full flex-1 flex flex-row">
                {isLoaded ? (
                    <Table
                        data={rows}
                        onNewRow={addRow}
                        columns={columns}
                        onSaveData={saveData}
                        onUpdateData={updateData}
                        csvRowValidator={csvRowValidator(
                            tableData,
                            operatingChainOptions
                        )}
                        onRemoveRow={deleteRow}
                        onRemoveAllRows={deleteAllRows}
                        addCsvData={addCsvData}
                        csvColumns={csvColumns}
                        components={{
                            filter: (
                                <Filter
                                    filter={filterSpec}
                                    onChange={setFilterSpec}
                                    operatingChainOptions={
                                        operatingChainOptions
                                    }
                                />
                            ),
                        }}
                        sampleCSV={sampleCSV}
                        rowsCSV={rowsCSV}
                        copyFiscalKey={copyFiscalKey}
                        showCopyFiscal
                        onCopyFrom={copyFiscalKey}
                        filterSpec={filterSpec}
                        fiscalKey={fiscalKeyQueryParam}
                        useHeaderExtraClasses4Columns
                    />
                ) : (
                    <div className="w-full text-center">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
};

export default NpsEmployees;
