import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import Modal from 'react-modal';
import { buttons, CSVForm } from '@/components';
import { DefineThresholds } from './DefineTresholds';

export const PayoutSystemsAction = (props) => {
    const { handleSave, thresholds, saveColumns, onCopyCsv } = props;
    const [showCSVForm, setShowCSVForm] = useState(false);
    const [showAddColumnModal, setShowAddColumnModal] = useState(false);
    const [validateRowNumber, setValidateRowNumber] = useState(null);
    const onSave = (state) => {
        setShowCSVForm(false);
        handleSave(state);
    };

    const onSaveColumns = (state) => {
        setShowAddColumnModal(false);
        saveColumns(state);
    };

    const sampleCSV = () => {
        return `Points lower bound\tPoints upper bound (not equal)\t0 - 90\t90 - 100\t100 - 110\t110 - 999
0\t5000\t0.1\t1\t3\t6
5000\t10000\t0.2\t1.2\t3.2\t6.2
10000\t20000\t0.3\t1.3\t3.4\t6.4
20000\t30000\t0.4\t1.5\t3.6\t6.6
30000\t40000\t0.5\t1.6\t3.8\t6.8
40000\t50000\t0.6\t1.8\t4\t7
50000\t60000\t0.7\t1.9\t4.2\t7.2
60000\t75000\t0.8\t2.1\t4.4\t7.4
75000\t100000\t0.9\t2.2\t4.6\t7.6
100000\t10000000\t1\t2\t3\t4`;
    };

    const csvColumnsValidator = (value) => {
        setValidateRowNumber(value.length);
    };

    const validateRow = (row) => {
        const isNumeric = (num) =>
            (typeof num === 'number' ||
                (typeof num === 'string' && num.trim() !== '' && num !== '')) &&
            !isNaN(num);

        const allNumbers = row.every(isNumeric);

        if (validateRowNumber !== row.length) {
            return {
                isValid: false,
                text: ['All entries must be filled.'],
            };
        }

        if (allNumbers) {
            return {
                isValid: true,
                text: ['OK'],
            };
        } else {
            return {
                isValid: false,
                text: ['All entries must be numbers.'],
            };
        }
    };

    const downloadCSV = (rowsCSV) => {
        if (rowsCSV) {
            const data = rowsCSV.split('\n').map((r) => r.split('\t'));
            const worksheet = XLSX.utils.aoa_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Table');
            XLSX.writeFile(workbook, 'table.xlsx');
        }
    };

    return (
        <>
            <buttons.Copy text="Copy csv" onClick={onCopyCsv} />
            <buttons.AddColumn
                text="Threshold"
                onAdd={() => setShowAddColumnModal(true)}
            />
            <buttons.AddCsv onAdd={() => setShowCSVForm(!showCSVForm)} />
            <buttons.SaveAsCSV
                onSave={(e) => {
                    e.preventDefault();
                    downloadCSV(props.rowsCSV);
                }}
            />
            <Modal
                isOpen={showCSVForm}
                className="bg-gray-700 w-full max-w-5xl h-auto rounded-lg"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-0 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowCSVForm(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <CSVForm
                    hasHeader={true}
                    onClose={() => setShowCSVForm(false)}
                    onSave={onSave}
                    sampleCSV={sampleCSV}
                    validateRow={validateRow}
                    csvColumnsValidator={csvColumnsValidator}
                />
            </Modal>
            <Modal
                isOpen={showAddColumnModal}
                className="bg-gray-700 w-full max-w-5xl h-auto rounded-lg"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-0 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowAddColumnModal(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <DefineThresholds
                    onSave={onSaveColumns}
                    onClose={() => setShowAddColumnModal(false)}
                    currentThresholds={thresholds}
                />
            </Modal>
        </>
    );
};
