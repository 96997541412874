import React from 'react';
import Toggle from './Toggle';

const ToggleCell = ({
    value,
    row: {
        original: { id: rowId },
    },
    column: { id },
    onUpdateData,
}) => {
    const config = { ...{ isDisabled: false }, ...value };
    return (
        <Toggle
            onChange={(value) => onUpdateData(rowId, id, value)}
            value={config.value}
        />
    );
};

export default ToggleCell;
