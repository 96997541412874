/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { Table, Loader } from '@/components';
import {
    columnTypes,
    removeRow,
    removeAllRows,
} from '@/components/Table/index';
import { PayoutSystemsAction } from './PayoutSystemsAction';
import { PayoutSystemsAction4Multiplier } from './PayoutSystemsAction4Multiplier';
import { copyText } from '@/utils';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';

const createRow = (step) => {
    const thresholds = step.thresholds.reduce(
        (acc, curr) => ({
            ...acc,
            [curr.thresholdId]: columnTypes.payoutColumn.cell(
                curr.rewardPercent
            ),
        }),
        {}
    );
    return {
        id: step.id,
        pointsFrom: columnTypes.pointsLowerBound.cell(step.pointsFrom),
        pointsTo: columnTypes.pointsUpperBound.cell(step.pointsTo),
        ...thresholds,
    };
};

const createRow4Multiplier = (steps) => {
    const thresholds = steps.reduce(
        (acc, curr) => ({
            ...acc,
            [curr.id]: columnTypes.payoutColumn.cell(curr.rewardPercent ?? 0),
        }),
        {}
    );

    return {
        id: steps[0].id,
        ...thresholds,
    };
};

const trimValues = (values, maxItems, allOption) => {
    const withoutAllOption = values.filter(
        (value) => value.value != allOption.value
    );
    if (withoutAllOption.length <= maxItems + 1) {
        return values;
    }
    const count = withoutAllOption.length - maxItems;
    const text = `${count} more...`;
    return [...withoutAllOption.slice(0, 3), text];
};

const ValueContainer = (props) => {
    const {
        children,
        selectProps: { allOption },
        ...rest
    } = props;
    const [values, input] = children;
    const newValues = Array.isArray(values)
        ? trimValues(values, 3, allOption)
        : values;
    return (
        <components.ValueContainer allOption={allOption} {...rest}>
            {newValues}
            {input}
        </components.ValueContainer>
    );
};

const animatedComponents = makeAnimated();
const ChecboxMultiSelect = (props) => {
    const options = props.allowSelectAll
        ? [props.allOption, ...props.options]
        : props.options;
    const onChange = !props.allowSelectAll
        ? props.onChange
        : (selected, event) => {
              if (selected !== null && selected.length > 0) {
                  if (
                      selected[selected.length - 1].value ===
                      props.allOption.value
                  ) {
                      return props.onChange([
                          props.allOption,
                          ...props.options,
                      ]);
                  }
                  let result = [];
                  if (selected.length === props.options.length) {
                      if (selected.includes(props.allOption)) {
                          result = selected.filter(
                              (option) => option.value !== props.allOption.value
                          );
                      } else if (event.action === 'select-option') {
                          result = [props.allOption, ...props.options];
                      }
                      return props.onChange(result);
                  }
              }

              return props.onChange(selected);
          };
    const modifiedProps = {
        ...props,
        options: options,
        isMulti: true,
        closeMenuOnSelect: false,
        hideSelectedOptions: false,
        components: {
            ValueContainer: ValueContainer,
            animatedComponents,
        },
        onChange: onChange,
    };

    return <Select {...modifiedProps} />;
};

ChecboxMultiSelect.defaultProps = {
    allOption: {
        label: 'Select all',
        value: '*',
    },
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PayoutSystems = () => {
    const { apiFactory } = useDependencies();
    const [tableData, setTableData] = useState([]);
    const [tableData4Multiplier, setTableData4Multiplier] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasData, setHasData] = useState(undefined);
    const [thresholds, setThresholds] = useState([]);
    const [thresholds4Multiplier, setThresholds4Multiplier] = useState([]);
    const [changedSavedState, setChangedSavedState] = useState(false);
    const [state, setState] = useState({});
    const [chosenOpChain, setChosenOpChain] = useState({});
    const query = useQuery();
    const [queryParams, setQueryParams] = useState({
        fiscalKey: query.get('fiscalKey') ?? '',
        opChain: query.get('opChain') ?? '',
    });
    const [columns, setColumns] = useState([]);
    const [columns4Multiplier, setColumns4Multiplier] = useState([]);
    const [selectedPayoutType, setSelectedPayoutType] = useState('GM');
    const [selectedPayoutType4Multiplier, setSelectedPayoutType4Multiplier] =
        useState('GM');
    const navigate = useNavigate();
    const { payoutSystemsApi, bonusSettingsApi, masterdataApi } = apiFactory;
    const getFiscalKeyOption = (fiscalKeyParam, options) => {
        if (options) {
            return (
                options
                    .flatMap((o) => o.options)
                    .find((fk) => fk.value == fiscalKeyParam) ?? { value: '' }
            );
        }
        return { value: '' };
    };

    const getOperatingChainOption = (opChainParam, options) => {
        return options
            ? options.find((op) => op.value === opChainParam)
            : null ?? { value: '' };
    };

    useEffect(() => {
        const createPayoutTable = (fiscalKeyData) => {
            if (
                !queryParams.opChain &&
                state.operatingChainOptions &&
                state.operatingChainOptions.length > 0
            ) {
                operatingChainChanged(state.operatingChainOptions[0]);
            }
            if (state.payoutSystems && queryParams.opChain) {
                const payoutSystem = state.payoutSystems.find(
                    (ps) =>
                        ps.operatingChain === queryParams.opChain &&
                        ps.fiscalKey === fiscalKeyData
                );
                const data = (payoutSystem || { data: undefined }).data;

                data && data.type
                    ? setSelectedPayoutType(data.type)
                    : setSelectedPayoutType('GM');

                data && data.multiplier?.type
                    ? setSelectedPayoutType4Multiplier(data.multiplier.type)
                    : setSelectedPayoutType4Multiplier('GM');

                const columns = [
                    columnTypes.pointsLowerBound.column(),
                    columnTypes.pointsUpperBound.column(),
                    ...(data && data.thresholdDefinitions
                        ? data.thresholdDefinitions
                        : []
                    ).map(columnTypes.payoutColumn.column),
                ];
                const rows = data ? data.steps?.map(createRow) : [];
                setTableData(rows);

                const columns4Multiplier = [
                    ...(data?.multiplier ? data.multiplier.steps : []).map(
                        columnTypes.payoutColumn.column
                    ),
                ];

                const rows4Multiplier =
                    data?.multiplier && data.multiplier.steps
                        ? [createRow4Multiplier(data.multiplier.steps)]
                        : [];
                setTableData4Multiplier(rows4Multiplier);
                setThresholds(data ? data.thresholdDefinitions : []);
                const multiplierData = data
                    ? data.multiplier?.steps?.map((step) => ({
                          id: step.id,
                          lowerBound: step.lowerBound,
                          upperBoundNotEqual: step.upperBoundNotEqual,
                      }))
                    : [];
                setThresholds4Multiplier(multiplierData);
                setColumns(columns);
                setColumns4Multiplier(columns4Multiplier);
            }
        };
        createPayoutTable(queryParams.fiscalKey);
    }, [queryParams, state]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const [payoutSystems, operatingChains, periodOptions] =
                await Promise.all([
                    payoutSystemsApi.getPayoutSystems(),
                    bonusSettingsApi.getOperatingChains(),
                    masterdataApi.getBonusPeriods({ includeEmpty: true }),
                ]);
            const operatingChainOptions = operatingChains.map((opChain) => ({
                value: opChain.operatingChain,
                label: opChain.description,
            }));
            if (mounted) {
                setState({
                    operatingChainOptions,
                    periodOptions,
                    payoutSystems,
                });
                setHasData(
                    payoutSystems && payoutSystems.length > 0 ? true : false
                );
            }
        }
        getData();
        return () => (mounted = false);
    }, [bonusSettingsApi, masterdataApi, payoutSystemsApi]);

    useEffect(() => {
        if (state.operatingChainOptions !== undefined) {
            const chosenOpChainData = state.operatingChainOptions.filter(
                (oc) => oc.value === queryParams.opChain
            );
            const newChosenOpChain = { operatingChain: chosenOpChainData[0] };
            setChosenOpChain(newChosenOpChain);
        }
    }, [state.operatingChainOptions, queryParams.opChain]);

    useEffect(() => {
        if ((hasData && tableData.length > 0) || hasData === false) {
            setIsLoaded(true);
        }
    }, [hasData, tableData]);

    const saveData = async () => {
        const transform = (tableData) => {
            const data = tableData.map((row) => {
                const thresholds = Object.keys(row)
                    .filter(
                        (prop) =>
                            ['id', 'pointsFrom', 'pointsTo'].indexOf(prop) < 0
                    )
                    .map((prop) => ({
                        thresholdId: prop,
                        rewardPercent: row[prop].value,
                    }));
                const step = {
                    id: row.id,
                    pointsFrom: row.pointsFrom.value,
                    pointsTo: row.pointsTo.value,
                    thresholds: thresholds,
                };
                return step;
            });

            const multiplierSteps = tableData4Multiplier.map((row) => {
                return Object.keys(row)
                    .filter((prop) => ['id'].indexOf(prop) < 0)
                    .map((prop) => ({
                        id: prop,
                        lowerBound: thresholds4Multiplier.filter(
                            (item) => item.id === prop
                        )[0].lowerBound,
                        upperBoundNotEqual: thresholds4Multiplier.filter(
                            (item) => item.id === prop
                        )[0].upperBoundNotEqual,
                        rewardPercent: row[prop].value,
                    }));
            });

            const jsonData =
                multiplierSteps && multiplierSteps.length > 0
                    ? {
                          steps: data,
                          thresholdDefinitions: thresholds,
                          type: selectedPayoutType,
                          multiplier: {
                              type: selectedPayoutType4Multiplier,
                              steps: multiplierSteps[0],
                          },
                      }
                    : {
                          steps: data,
                          thresholdDefinitions: thresholds,
                          type: selectedPayoutType,
                      };

            return {
                data: jsonData,
                fiscalKey: queryParams.fiscalKey,
                operatingChain: queryParams.opChain,
                simulationId: simulationIdState.get()[0].simulationId,
            };
        };

        if (selectedPayoutType === selectedPayoutType4Multiplier && tableData4Multiplier.length > 0) {
            return {
                valid: false,
                message:
                    'The measure for multiplier and points cannot be the same',
            };
        }
        const data = transform(tableData);
        await payoutSystemsApi.savePayoutSystems(data);
        const savedData = await payoutSystemsApi.getPayoutSystems(true);
        setState({ ...state, payoutSystems: savedData });
        setChangedSavedState(false);
        return {
            valid: true,
        };
    };

    const createQueryString = ({ fiscalKey, opChain }) => {
        return `?fiscalKey=${fiscalKey || ''}&opChain=${opChain || ''}`;
    };

    const updateQuery = (queryParams) => {
        setQueryParams(queryParams);
        navigate({
            search: createQueryString(queryParams),
        });
    };

    const periodChanged = (selectedValue) => {
        updateQuery({ ...queryParams, fiscalKey: selectedValue.value });
    };

    const operatingChainChanged = (selectedValue) => {
        updateQuery({ ...queryParams, opChain: selectedValue.value });
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData));
    };

    const deleteRow4Multiplier = (id) => {
        setTableData4Multiplier(removeRow(id, tableData4Multiplier));
        setChangedSavedState(true);
    };

    const deleteAllRows4Multiplier = () => {
        setTableData4Multiplier(removeAllRows(tableData4Multiplier));
        setChangedSavedState(true);
    };

    const sampleCSV = () => {
        return `Points lower bound\tPoints upper bound (not equal)\t0 - 90\t90 - 100\t100 - 110\t110 - 999
0\t5000\t0.1\t1\t3\t6
5000\t10000\t0.2\t1.2\t3.2\t6.2
10000\t20000\t0.3\t1.3\t3.4\t6.4
20000\t30000\t0.4\t1.5\t3.6\t6.6
30000\t40000\t0.5\t1.6\t3.8\t6.8
40000\t50000\t0.6\t1.8\t4\t7
50000\t60000\t0.7\t1.9\t4.2\t7.2
60000\t75000\t0.8\t2.1\t4.4\t7.4
75000\t100000\t0.9\t2.2\t4.6\t7.6
100000\t10000000\t1\t2\t3\t4`;
    };

    const sampleCSV4Multiplier = () => {
        return `0 - 90\t90 - 100\t100 - 110\t110 - 999
10\t20\t30\t40`;
    };

    const importCsv = (data) => {
        const header = data.columns;
        const rows = data.data;
        const thresholdsDefinitions = header.slice(2).map((c) => {
            const [lowerBound, upperBound] = c.split('-').map((v) => v.trim());
            return {
                id: uuidv4(),
                lowerBound: lowerBound,
                upperBoundNotEqual: upperBound,
            };
        });
        const columns = [
            columnTypes.pointsLowerBound.column(),
            columnTypes.pointsUpperBound.column(),
            ...thresholdsDefinitions.map(columnTypes.payoutColumn.column),
        ];
        setColumns(columns);
        setThresholds(thresholdsDefinitions);
        const tableData = rows.map((value) => {
            const items = value.items;
            const step = {
                id: uuidv4(),
                pointsFrom: items[0],
                pointsTo: items[1],
                thresholds: items.slice(2).map((rewardPercent, index) => ({
                    rewardPercent: rewardPercent,
                    thresholdId: thresholdsDefinitions[index].id,
                })),
            };
            return createRow(step);
        });
        setTableData(tableData);
        setChangedSavedState(true);
    };

    const importCsv4Multiplier = (data) => {
        const header = data.columns;
        const rows = data.data;
        const thresholdsDefinitions = header.map((c) => {
            const [lowerBound, upperBound] = c.split('-').map((v) => v.trim());
            return {
                id: uuidv4(),
                lowerBound: lowerBound,
                upperBoundNotEqual: upperBound,
            };
        });
        const columns = [
            ...thresholdsDefinitions.map(columnTypes.payoutColumn.column),
        ];
        setColumns4Multiplier(columns);
        setThresholds4Multiplier(thresholdsDefinitions);
        const tableData = rows.map((value) => {
            const items = value.items;
            const step = {
                id: uuidv4(),
                thresholds: items.map((rewardPercent, index) => ({
                    rewardPercent: rewardPercent,
                    thresholdId: thresholdsDefinitions[index].id,
                })),
            };
            return createRow(step);
        });
        setTableData4Multiplier(tableData);
        setChangedSavedState(true);
    };

    const addRow = () => {
        const step = {
            id: uuidv4(),
            pointsFrom: 0,
            pointsTo: 0,
            thresholds: columns.slice(2).map((col) => ({
                rewardPercent: 0,
                thresholdId: col.accessor,
            })),
        };
        setTableData([...tableData, createRow(step)]);
    };

    const INITIAL_TRESHOLDS = [
        {
            id: uuidv4(),
            lowerBound: 0,
            upperBoundNotEqual: 80,
            rewardPercent: 100,
        },
    ];

    const addRow4Multiplier = () => {
        const thresholdDefinitions =
            thresholds4Multiplier && thresholds4Multiplier.length > 0
                ? thresholds4Multiplier
                : INITIAL_TRESHOLDS;
        setTableData4Multiplier([createRow4Multiplier(thresholdDefinitions)]);
        saveColumns4Multiplier(thresholdDefinitions);
    };

    const saveColumns = (data) => {
        const newColumns = [
            ...columns.slice(0, 2),
            ...data.map(columnTypes.payoutColumn.column),
        ];
        const newTableData = tableData.map((row) => {
            const values = data.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.id]: row[curr.id]
                        ? row[curr.id]
                        : columnTypes.payoutColumn.cell(0),
                }),
                {}
            );
            return { ...row, ...values };
        });
        setColumns(newColumns);
        setTableData(newTableData);
        setThresholds(data);
    };

    const saveColumns4Multiplier = (data) => {
        const newColumns = [...data.map(columnTypes.payoutColumn.column)];
        let newTableData =
            tableData4Multiplier && tableData4Multiplier.length > 0
                ? tableData4Multiplier
                : [{}];
        newTableData = newTableData.map((row) => {
            const values = data.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.id]: row[curr.id]
                        ? row[curr.id]
                        : columnTypes.payoutColumn.cell(0),
                }),
                {}
            );
            return { ...row, ...values };
        });
        setColumns4Multiplier(newColumns);
        setTableData4Multiplier(newTableData);
        setThresholds4Multiplier(data);
    };

    const handleCopyCsv = () => {
        const headers = columns
            .map((c) => c.Header.replaceAll('%', ''))
            .join(',');
        const rows = tableData.map((row) =>
            Object.keys(row)
                .filter((k) => k != 'id')
                .map((k) => row[k].value)
                .join(',')
        );
        const allLines = [headers, ...rows].join('\n');
        copyText(allLines, () => {
            toast.success('CSV data copied to clipboard', {
                position: toast.POSITION.TOP_RIGHT,
            });
        });
    };

    const rows = useMemo(() => {
        return [...tableData];
    }, [tableData]);

    const rows4Multiplier = useMemo(() => {
        return [...tableData4Multiplier];
    }, [tableData4Multiplier]);

    // Convert table data to string
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const headers = columns
                .map((c) => c.Header.replaceAll('%', ''))
                .join('\t');
            const rowsStr = tableData.map((row) =>
                Object.keys(row)
                    .filter((k) => k != 'id')
                    .map((k) => row[k].value)
                    .join('\t')
            );
            const allLines = [headers, ...rowsStr].join('\n');
            return allLines;
        }
    }, [rows, columns, tableData]);

    const rowsCSV4Multiplier = useMemo(() => {
        if (rows4Multiplier.length > 0) {
            const headers = columns4Multiplier
                .map((c) => c.Header.replaceAll('%', ''))
                .join('\t');
            const rowsStr = tableData4Multiplier.map((row) =>
                Object.keys(row)
                    .filter((k) => k != 'id')
                    .map((k) => row[k].value)
                    .join('\t')
            );
            const allLines = [headers, ...rowsStr].join('\n');
            return allLines;
        }
    }, [rows4Multiplier, columns4Multiplier, tableData4Multiplier]);

    const actualColumns = useMemo(() => {
        return [...columns];
    }, [columns]);

    const actualColumns4Multiplier = useMemo(() => {
        return [...columns4Multiplier];
    }, [columns4Multiplier]);

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row[columnName] = { ...row[columnName], ...{ value } };
        setTableData(newTableData);
    };

    const updateData4Multiplier = (id, columnName, value) => {
        const newTableData = [...tableData4Multiplier];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row[columnName] = { ...row[columnName], ...{ value } };
        setTableData4Multiplier(newTableData);
        setChangedSavedState(true);
    };

    const createCopyOfPayoutTable = (fiscalKeyData, operatingChainData) => {
        if (state.payoutSystems && operatingChainData) {
            const payoutSystem = state.payoutSystems.find(
                (ps) =>
                    ps.operatingChain === operatingChainData &&
                    ps.fiscalKey === fiscalKeyData
            );
            const data = (payoutSystem || { data: undefined }).data;

            const columns = [
                columnTypes.pointsLowerBound.column(),
                columnTypes.pointsUpperBound.column(),
                ...(data && data.thresholdDefinitions
                    ? data.thresholdDefinitions
                    : []
                ).map(columnTypes.payoutColumn.column),
            ];
            const rows = data ? data.steps.map(createRow) : [];
            if (rows.length === 0) {
                return rows;
            }
            setTableData(rows);
            setThresholds(data ? data.thresholdDefinitions : []);
            setColumns(columns);
            setSelectedPayoutType(data?.type ?? 'GM');

            // Multiplier part
            const columns4Multiplier = [
                ...(data?.multiplier ? data.multiplier.steps : []).map(
                    columnTypes.payoutColumn.column
                ),
            ];
            const rows4Multiplier = data?.multiplier
                ? [createRow4Multiplier(data.multiplier.steps)]
                : [];
            setTableData4Multiplier(rows4Multiplier);
            setThresholds4Multiplier(
                data?.multiplier ? data.multiplier.steps : []
            );
            setColumns4Multiplier(columns4Multiplier);
            setSelectedPayoutType4Multiplier(data.multiplier?.type ?? 'GM');

            return rows;
        }
    };

    const copyFiscalKey = (fiscalKeyData, operatingChainData) => {
        !fiscalKeyData
            ? (fiscalKeyData = { label: 'General', value: '' })
            : null;
        return createCopyOfPayoutTable(
            fiscalKeyData.value,
            operatingChainData.value
        );
    };

    const payoutTypeOptions = [
        {
            label: 'GM',
            value: 'GM',
        },
        {
            label: 'Revenue including commission',
            value: 'REVEXINCCOMM',
        },
        {
            label: 'Revenue',
            value: 'REVEX',
        },
    ];

    return (
        <div className="flex w-full h-full flex-col">
            <div className="pt-8 w-full flex flex-col gap-4 justify-center items-center">
                <div className="flex flex-row justify-center items-center gap-2 justify-self-center">
                    <label className="font-bold">Period: </label>
                    <Select
                        options={state.periodOptions}
                        onChange={periodChanged}
                        className="w-48 inline-block text-gray-900"
                        value={getFiscalKeyOption(
                            queryParams.fiscalKey,
                            state.periodOptions
                        )}
                    />
                    <Select
                        options={state.operatingChainOptions}
                        onChange={operatingChainChanged}
                        className="w-60 inline-block text-gray-900"
                        value={getOperatingChainOption(
                            queryParams.opChain,
                            state.operatingChainOptions
                        )}
                    />
                </div>
                <div className="w-1/5 flex flex-row justify-center items-center gap-2 justify-self-center">
                    <label className="font-bold">Points: </label>
                    <Select
                        options={payoutTypeOptions}
                        onChange={(e) => {
                            setSelectedPayoutType(e.value);
                            setChangedSavedState(true);
                        }}
                        value={payoutTypeOptions.filter(
                            (option) => option.value === selectedPayoutType
                        )}
                        className="w-96 inline-block text-gray-900"
                    />
                </div>
            </div>
            <div className="content w-full flex flex-row">
                {isLoaded ? (
                    <Table
                        data={rows}
                        onNewRow={addRow}
                        columns={actualColumns}
                        onSaveData={saveData}
                        onRemoveRow={deleteRow}
                        onRemoveAllRows={deleteAllRows}
                        onUpdateData={updateData}
                        components={{
                            extraActions: (
                                <PayoutSystemsAction
                                    handleSave={importCsv}
                                    saveColumns={saveColumns}
                                    thresholds={thresholds}
                                    rowsCSV={rowsCSV}
                                    onCopyCsv={handleCopyCsv}
                                />
                            ),
                        }}
                        showCopyFiscal={true}
                        opChainMultiSelect={false}
                        rowsCSV={rowsCSV}
                        onCopyFrom={copyFiscalKey}
                        filterSpec={chosenOpChain}
                        fiscalKey={queryParams.fiscalKey}
                        componentName={'PayoutSystems'}
                        changedSavedState={changedSavedState}
                        sampleCSV={sampleCSV}
                        addCsvData={importCsv}
                    />
                ) : (
                    <div className="w-full text-center">
                        <Loader />
                    </div>
                )}
            </div>
            {isLoaded && (
                <div className="w-1/5 flex flex-row justify-center items-center gap-2 justify-self-center mx-auto">
                    <label className="font-bold">Multiplier: </label>
                    <Select
                        options={payoutTypeOptions}
                        onChange={(e) => {
                            setSelectedPayoutType4Multiplier(e.value);
                            setChangedSavedState(true);
                        }}
                        value={payoutTypeOptions.filter(
                            (option) =>
                                option.value === selectedPayoutType4Multiplier
                        )}
                        className="w-96 inline-block text-gray-900"
                    />
                </div>
            )}
            <div className="content w-full flex flex-row">
                {isLoaded && (
                    <Table
                        data={rows4Multiplier}
                        onNewRow={addRow4Multiplier}
                        columns={actualColumns4Multiplier}
                        onRemoveRow={deleteRow4Multiplier}
                        onRemoveAllRows={deleteAllRows4Multiplier}
                        onUpdateData={updateData4Multiplier}
                        opChainMultiSelect={false}
                        rowsCSV={rowsCSV4Multiplier}
                        filterSpec={chosenOpChain}
                        fiscalKey={queryParams.fiscalKey}
                        componentName={'PayoutSystemsMultiplier'}
                        changedSavedState={changedSavedState}
                        components={{
                            extraActions: (
                                <PayoutSystemsAction4Multiplier
                                    handleSave={importCsv4Multiplier}
                                    saveColumns={saveColumns4Multiplier}
                                    setChangedSavedState={setChangedSavedState}
                                    rowsCSV={rowsCSV4Multiplier}
                                    thresholds={
                                        thresholds4Multiplier &&
                                        thresholds4Multiplier.length > 0
                                            ? thresholds4Multiplier
                                            : []
                                    }
                                />
                            ),
                        }}
                        sampleCSV={sampleCSV4Multiplier}
                        addCsvData={importCsv4Multiplier}
                        hideSaveButton
                    />
                )}
            </div>
        </div>
    );
};

export default PayoutSystems;
