import React from 'react';

const Toggle = ({ value, onChange }) => {
    const handleClick = () => {
        onChange(!value);
    };

    const extraClasses = value ? 'bg-green-400' : 'bg-gray-300';
    const bgClassName = `w-20 h-10 flex items-center bg-gray-300 rounded-full p-1 duration-100 ease-in-out ${extraClasses}`;
    const switchClassName =
        'bg-white w-8 h-8 rounded-full shadow-md transform duration-100 ease-in-out ' +
        (value ? 'translate-x-10' : '');
    const textConfig = value
        ? { class: 'transform -translate-x-6', text: 'ON' }
        : { class: 'transform translate-x-2', text: 'OFF' };
    return (
        <div
            className="flex justify-between items-center"
            onClick={handleClick}
        >
            <div className={bgClassName}>
                <div className={switchClassName}></div>
                <div className={textConfig.class}>{textConfig.text}</div>
            </div>
        </div>
    );
};

export default Toggle;
