import React, { useState, useEffect, useMemo } from 'react';
import { Table, Loader } from '@/components';
import { v4 as uuidv4 } from 'uuid';
import { valueTypes, validators } from '@/utils';
import {
    columnTypes,
    removeRow,
    toggleRow,
    toggleAllRows,
    removeSelected,
    filters,
} from '@/components/Table/index';
import { saveBonusGeneralAsFormData, checkRowValue } from './common';
import { useDependencies } from '@/DependencyProvider';
import { simulationIdState } from '@/simulationIdState';

const createKeyValueArgs = ({
    categories,
    searchArticles,
    searchModels,
    searchBrands,
    searchGroups,
    modelTypesItems,
}) => {
    return {
        getCategories: () => categories,
        getArticles: searchArticles,
        getModels: searchModels,
        getBrands: searchBrands,
        getGroups: searchGroups,
        getModelTypes: () => {
            return modelTypesItems;
        },
    };
};

const createRow =
    (ruleTypes, keyValueArgs, operatingChainOptions) =>
    (individualSalesEntry) => {
        const row = {
            id: individualSalesEntry.id,
            altId: individualSalesEntry.altId,
            selected: false,
            key: columnTypes.ruleType.cell(individualSalesEntry.key, ruleTypes),
            keyValue: columnTypes.keyValue.cell(
                individualSalesEntry.keyValue,
                individualSalesEntry.key,
                keyValueArgs
            ),
            valueType: columnTypes.valueType.cell(
                individualSalesEntry.valueType,
                individualSalesEntry.key,
                individualSalesEntry.keyValue
            ),
            revGMIndicator: columnTypes.revGMIndicator.cell(
                individualSalesEntry.revGMIndicator,
                individualSalesEntry.key,
                individualSalesEntry.keyValue,
                individualSalesEntry.valueType
            ),
            startDate: columnTypes.startDate.cell(
                individualSalesEntry.startDate
            ),
            endDate: columnTypes.endDate.cell(individualSalesEntry.endDate),
            operatingChain: columnTypes.operatingChainType.cell(
                individualSalesEntry.operatingChain,
                operatingChainOptions
            ),
            value: columnTypes.operatingChainValueTwo.cell(
                individualSalesEntry.value
            ),
        };
        return row;
    };

const Filter = (props) => {
    return (
        <>
            <filters.OperatingChainFilter {...props} />
            <filters.ActiveFilter {...props} />
        </>
    );
};

export default function DateSpecific({ staffType }) {
    const { apiFactory } = useDependencies();
    const [isLoaded, setIsLoaded] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [state, setState] = useState({});
    const [filterSpec, setFilterSpec] = useState({
        operatingChain: null,
        activeFilter: null,
    });

    const getOptions = async (getter) => {
        const response = await getter();
        return response.map((c) => ({
            value: c.id,
            label: `${c.id} - ${c.description}`,
        }));
    };

    useEffect(() => {
        if (state.data && state.ruleTypes && state.keyValueArgs) {
            const data = state.data.flatMap((d) => d.data);
            const obj = createNewObject(data);
            const tableData = obj
                .map(
                    createRow(
                        state.ruleTypes,
                        state.keyValueArgs,
                        state.operatingChainOptions
                    )
                )
                .filter((obj) => obj.operatingChain.label.length > 0);

            setTableData(tableData);
            setIsLoaded(true);
        }
    }, [state]);

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const {
                bonusSettingsApi,
                articleMasterdataApi,
                bonusRuleApi,
                searchApi,
            } = apiFactory;

            const [
                measures,
                categories,
                operatingChains,
                models,
                brands,
                modelTypes,
                groups,
                data,
            ] = await Promise.all([
                bonusSettingsApi.getMeasures(),
                getOptions(articleMasterdataApi.getCategories),
                bonusSettingsApi.getOperatingChains(),
                getOptions(articleMasterdataApi.getModels),
                getOptions(articleMasterdataApi.getBrands),
                articleMasterdataApi.getModelTypes(),
                getOptions(articleMasterdataApi.getGroups),
                staffType === 'operations'
                    ? bonusRuleApi.getDateSpecificOperations()
                    : bonusRuleApi.getDateSpecificSales(),
            ]);

            const measureMap = measures.reduce((acc, m) => {
                acc[m.key] = m;
                return acc;
            }, {});
            const filteredMeasures = Object.values(measureMap);
            const ruleTypes = filteredMeasures
                .filter((m) => m.dateSpecific)
                .map((m) => {
                    return {
                        value: m.key,
                        label: m.description,
                    };
                });

            const createFilteredOptions = (list, includeValue) => (input) => {
                const lowerInput = (
                    input && typeof input === 'string' ? input : ''
                ).toLowerCase();
                const filtered = list
                    .filter(
                        (b) =>
                            b.value.toLowerCase().includes(lowerInput) ||
                            b.label.toLowerCase().includes(lowerInput)
                    )
                    .sort((y) => y.value.length)
                    .slice(0, 10);
                if (
                    includeValue &&
                    filtered.map((y) => y.value).indexOf(input) < 0
                ) {
                    return [{ value: input, label: input }, ...filtered];
                }
                return filtered;
            };
            const searchBrands = createFilteredOptions(brands);
            const searchModels = createFilteredOptions(models, true);
            const searchGroups = createFilteredOptions(groups, true);
            const modelTypesItems = modelTypes.map((c) => ({
                value: c.id,
                label: `${c.id} - ${c.description}`,
            }));
            const keyValueArgs = createKeyValueArgs({
                categories,
                searchArticles: searchApi.searchArticles,
                searchModels,
                searchBrands,
                searchGroups,
                modelTypesItems,
            });
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            mounted
                ? setState({
                      keyValueArgs,
                      ruleTypes,
                      operatingChains,
                      data,
                      operatingChainOptions,
                  })
                : '';
        }
        getData();
        return () => (mounted = false);
    }, [apiFactory, staffType]);

    const columns = useMemo(() => {
        if (state.operatingChains) {
            const fixedColumns = [
                columnTypes.operatingChainType.column(),
                columnTypes.ruleType.column(),
                columnTypes.keyValue.column(true),
                columnTypes.valueType.column(true),
                columnTypes.revGMIndicator.column(),
                columnTypes.startDate.column(),
                columnTypes.endDate.column(),
                columnTypes.operatingChainValueTwo.column(),
            ];
            return [...fixedColumns];
        } else {
            return [];
        }
    }, [state]);

    const createNewObject = (dateSpecificEntries) => {
        const newDateSpecificEntries = [];
        dateSpecificEntries.forEach((newDateSpecificEntry) => {
            newDateSpecificEntry.operatingChains.forEach((el, index) => {
                if (checkRowValue(el.value)) {
                    newDateSpecificEntries.push({
                        id: newDateSpecificEntry.id + '#' + index,
                        altId: newDateSpecificEntry.id,
                        key: newDateSpecificEntry.key,
                        keyValue: newDateSpecificEntry.keyValue,
                        operatingChain: el.operatingChain,
                        value: el.value,
                        valueType: newDateSpecificEntry.valueType,
                        revGMIndicator: newDateSpecificEntry.revGMIndicator,
                        startDate: newDateSpecificEntry.startDate,
                        endDate: newDateSpecificEntry.endDate,
                    });
                }
            });
        });
        return newDateSpecificEntries;
    };

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row.id = uuidv4();
        row.altId = row.id;
        row[columnName] = { ...row[columnName], ...{ value } };
        if (columnName === 'value') {
            row[columnName] = parseFloat(value).toFixed(2);
        }
        if (columnName === 'key') {
            row['keyValue'] = columnTypes.keyValue.cell(
                '',
                value,
                state.keyValueArgs
            );
            row['valueType'] = columnTypes.valueType.cell('', value);
        }
        if (columnName === 'keyValue') {
            const keyCell = row['key'];
            row['valueType'] = columnTypes.valueType.cell(
                '',
                keyCell.value,
                value
            );
        }
        if (
            ['key', 'keyValue', 'valueType', 'revGMIndicator'].indexOf(
                columnName
            ) > -1
        ) {
            const keyValue = columnName === 'key' ? value : row['key'].value;
            const keyValueValue =
                columnName === 'keyValue' ? value : row['keyValue'].value;
            const valueTypeValue =
                columnName === 'valueType' ? value : row['valueType'].value;
            const revGMIndicatorValue =
                columnName === 'revGMIndicator'
                    ? value
                    : row['revGMIndicator'].value;
            row['revGMIndicator'] = columnTypes.revGMIndicator.cell(
                revGMIndicatorValue,
                keyValue,
                keyValueValue,
                valueTypeValue
            );
        }
        setTableData(newTableData);
    };

    const newRow = () => {
        const operatingChains = columns
            .filter(
                (c) =>
                    ['id', 'key', 'keyValue', 'valueType'].indexOf(c.accessor) <
                    0
            )
            .map((c) => ({ operatingChain: c.accessor, value: 0 }));
        const total = {
            id: uuidv4(),
            valueType: valueTypes.percentage,
            keyValue: '',
            operatingChains: operatingChains,
        };
        total.altId = total.id;
        const row = createRow(
            state.ruleTypes,
            state.keyValueArgs,
            state.operatingChainOptions
        )(total);
        const newTableData = [...tableData, row];
        setTableData(newTableData);
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteSelected = () => setTableData(removeSelected(tableData));

    const toggleItem = (id) => setTableData(toggleRow(id, tableData));

    const toggleAll = (ids, shouldToggle) => {
        setTableData(toggleAllRows(ids, shouldToggle, tableData));
    };

    const hasSameValues = (valueOne, valueTwo) => {
        return (
            valueOne.key === valueTwo.key.value &&
            valueOne.keyValue === valueTwo.keyValue.value &&
            valueOne.revGMIndicator === valueTwo.revGMIndicator.value &&
            valueOne.valueType === valueTwo.valueType.value &&
            valueOne.startDate === valueTwo.startDate.value &&
            valueOne.endDate === valueTwo.endDate.value
        );
    };

    const saveData = async () => {
        const formattedIndividualSales = [];
        tableData.forEach((individualSale) => {
            if (
                !formattedIndividualSales.some((e) =>
                    hasSameValues(e, individualSale)
                ) ||
                formattedIndividualSales.length === 0
            ) {
                formattedIndividualSales.push({
                    id: individualSale.altId,
                    operatingChains: [
                        {
                            operatingChain: individualSale.operatingChain.value,
                            value: individualSale.value,
                        },
                    ],
                    key: individualSale.key.value,
                    valueType: individualSale.valueType.value,
                    keyValue: individualSale.keyValue.value,
                    revGMIndicator: individualSale.revGMIndicator.value,
                    startDate: individualSale.startDate.value,
                    endDate: individualSale.endDate.value,
                });
            } else {
                formattedIndividualSales.forEach((el) => {
                    if (hasSameValues(el, individualSale)) {
                        el.operatingChains.push({
                            operatingChain: individualSale.operatingChain.value,
                            value: individualSale.value,
                        });
                    }
                });
            }
        });

        const bonusRuleApi = apiFactory.bonusRuleApi;
        const saveFunction =
            staffType === 'operations'
                ? bonusRuleApi.saveDateSpecificOperations
                : bonusRuleApi.saveDateSpecificSales;
        await saveBonusGeneralAsFormData(saveFunction)(
            formattedIndividualSales,
            null,
            simulationIdState.get()[0].simulationId
        );
        const savedData = await (staffType === 'operations'
            ? bonusRuleApi.getDateSpecificOperations(true)
            : bonusRuleApi.getDateSpecificSales(true));
        setState({ ...state, data: savedData });
        return {
            valid: true,
        };
    };

    const activeFilter = (filter, row) => {
        if (!filter.activeFilter || filter.activeFilter.value === '') {
            return true;
        }
        const startDate = new Date(row.startDate.value);
        const endDate = new Date(row.endDate.value);
        const now = new Date();

        if (filter.activeFilter.value === 'Passed' && endDate < now) {
            return true;
        }
        if (filter.activeFilter.value === 'Future' && startDate > now) {
            return true;
        }
        if (
            filter.activeFilter.value === 'Active' &&
            startDate <= now &&
            endDate >= now
        ) {
            return true;
        }
        return false;
    };

    const rows = useMemo(() => {
        const filter = filters.composeFilters([
            filters.operatingChain,
            activeFilter,
        ])(filterSpec);
        const filtered = tableData.filter(filter);
        return [...filtered];
    }, [filterSpec, tableData]);

    // Convert table data to string
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) =>
                    `${row.operatingChain.value}\t${row.key.value}\t${
                        row.keyValue.value
                    }\t${row.valueType.value}\t${
                        row.revGMIndicator.isVisible
                            ? row.revGMIndicator.value
                            : 'null'
                    }\t${row.startDate.value}\t${row.endDate.value}\t${
                        row.value
                    }`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    const sampleCSV = () => {
        return `OCNOELK\tCATEGORYCODE\t7\tPERCENTAGE\tGM\t2021-01-01\t2022-05-17\t2
OCNOELK\tGROUPCODE\t543\tPERCENTAGE\tGM\t2021-01-01\t2022-05-17\t2
OCNOELK\tARTICLECODE\t156441\tPERCENTAGE\tGM\t2021-01-01\t2022-05-17\t2
OCNOELK\tBRANDCODE\t868\tPERCENTAGE\tREV\t2021-01-01\t2022-05-17\t2
OCNOELK\tMODELTYPECODE\tINS\tPERCENTAGE\tREV\t2021-01-01\t2022-05-17\t2
OCNOELK\tCUSTOMER_RECRUITMENT\tnull\tFIXED\tnull\t2021-01-01\t2022-05-17\t5
        `;
    };

    const { returnNullOrValue } = validators;
    const returnNullOrValueExtended = (str) => {
        if (returnNullOrValue(str)) {
            return str === 'NULL' ? undefined : str;
        }
    };

    const checkIfRowAlreadyExist = (uploadedRow, existingRow) => {
        return (
            uploadedRow.key === existingRow.key.value &&
            uploadedRow.operatingChain === existingRow.operatingChain.value &&
            returnNullOrValue(uploadedRow.keyValue) ===
                returnNullOrValue(existingRow.keyValue.value) &&
            returnNullOrValue(uploadedRow.revGMIndicator) ===
                returnNullOrValue(existingRow.revGMIndicator.value) &&
            uploadedRow.valueType === existingRow.valueType.value &&
            uploadedRow.startDate === existingRow.startDate.value &&
            uploadedRow.endDate === existingRow.endDate.value
        );
    };
    const addCsvData = (data) => {
        const createItem = (row) => {
            const item = {
                id: uuidv4(),
                selected: false,
                operatingChain: row.items[0],
                key: row.items[1].toUpperCase(),
                keyValue: returnNullOrValueExtended(row.items[2].toUpperCase()),
                valueType: row.items[3].toUpperCase(),
                revGMIndicator: returnNullOrValue(row.items[4].toUpperCase()),
                startDate: row.items[5],
                endDate: row.items[6],
                value: row.items[7].replace(',', '.'),
            };
            item.altId = item.id;
            item.id = item.id + '#' + 1;
            return item;
        };
        const input = data.data.map(createItem);

        const existingRows = tableData.map((tr) => {
            const updatedRow = input.find((i) => checkIfRowAlreadyExist(i, tr));
            if (updatedRow) {
                tr.value = updatedRow.value;
            }
            return tr;
        });
        const newRows = input
            .filter(
                (r) =>
                    existingRows.findIndex((ud) =>
                        checkIfRowAlreadyExist(r, ud)
                    ) < 0
            )
            .map(
                createRow(
                    state.ruleTypes,
                    state.keyValueArgs,
                    state.operatingChainOptions
                )
            );
        setTableData([...existingRows, ...newRows]);
    };

    const keyValidator = (ruleTypes) => (row) => {
        const key = row[1];
        const validValues = ruleTypes.map((rt) => rt.value);
        const validKey = validValues.indexOf(key) >= 0;
        return {
            isValid: validKey,
            errorMessage: validKey
                ? ''
                : 'Invalid rule type, valid values are: ' +
                  validValues.join(','),
        };
    };

    const keyValueValidator = (keyValueArgs) => async (row) => {
        const ruleTypesWithoutKeyValue = [
            'OUTLET',
            'B2B',
            'KITCHEN',
            'CUSTOMER_RECRUITMENT',
        ];
        const optionsForKey = columnTypes.keyValue.getOptions(
            keyValueArgs,
            row[1]
        );
        const keyValue = row[2];
        const options = Array.isArray(optionsForKey)
            ? optionsForKey
            : await optionsForKey(keyValue);
        const isValid =
            options.map((o) => o.value).indexOf(keyValue) >= 0 ||
            (ruleTypesWithoutKeyValue.includes(row[1]) && keyValue === 'null');
        return {
            isValid: isValid,
            errorMessage: isValid ? '' : 'Invalid value for rule',
        };
    };

    const valueTypeValidator = (row) => {
        let validValues = valueTypes.options.map((o) => o.value);
        row[1] === 'CUSTOMER_RECRUITMENT' ? (validValues = ['FIXED']) : '';
        const isValid = validValues.indexOf(row[3]) >= 0;
        return {
            isValid: isValid,
            errorMessage: isValid
                ? ''
                : `Invalid value type, valid values: ${JSON.stringify(
                      validValues
                  )}`,
        };
    };

    const revGMIndicatorValidator = (row) => {
        let validValues = [
            ...columnTypes.revGMIndicator.revGMOptions.map((o) => o.value),
            '',
        ];
        row[3] === 'FIXED' ? (validValues = ['null']) : '';
        const isValid = validValues.indexOf(row[4]) >= 0;
        return {
            isValid: isValid,
            errorMessage: isValid
                ? ''
                : `Invalid indicator, valid values: ${JSON.stringify(
                      validValues
                  )}`,
        };
    };

    const dateValidator = (cellIndex) => (row) => {
        const dateStr = row[cellIndex];
        const parts = dateStr.split('-');
        const isValid = parts.length === 3 && Date.parse(dateStr);
        return {
            isValid: isValid,
            errorMessage: isValid
                ? ''
                : 'Invalid date, valid format yyyy-MM-dd',
        };
    };

    const operatingChainValueValidation = (cellIndex) => (row) => {
        const value = row[cellIndex].replace(',', '.');
        const number = Number(value);
        const isValid = !isNaN(number);
        return {
            isValid: isValid,
            errorMessage: isValid ? '' : 'The value is not a number',
        };
    };

    const csvRowValidator = async (row) => {
        const startDateValidator = dateValidator(5);
        const endDateValidator = dateValidator(6);
        const validators = [
            keyValidator(state.ruleTypes),
            keyValueValidator(state.keyValueArgs),
            valueTypeValidator,
            revGMIndicatorValidator,
            startDateValidator,
            endDateValidator,
            operatingChainValueValidation(7),
        ];
        const validationResults = await Promise.all(
            validators.map((v) => v(row))
        );
        const result = validationResults.reduce(
            (acc, curr) => {
                return {
                    isValid: acc.isValid && curr.isValid,
                    text:
                        curr.errorMessage === ''
                            ? acc.text
                            : [...acc.text, curr.errorMessage],
                };
            },
            { isValid: true, text: [] }
        );
        return {
            isValid: result.isValid,
            text: result.isValid
                ? result.text.filter((y) => y != '')
                : result.text,
        };
    };

    const copyOpchains = (fromOpChains, toOpChain) => {
        const data = state.data.flatMap((d) => d.data);

        const obj = createNewObject(data);

        const copiedObjects = obj
            .filter((nt) =>
                fromOpChains.map((op) => op.value).includes(nt.operatingChain)
            )
            .map((fnt) => {
                return {
                    ...fnt,
                    id: uuidv4(),
                    operatingChain: toOpChain.value,
                };
            });

        const mergedObjects = [...obj, ...copiedObjects];

        const newTableData = mergedObjects.map(
            createRow(
                state.ruleTypes,
                state.keyValueArgs,
                state.operatingChainOptions
            )
        );
        setTableData(newTableData);
    };

    return isLoaded ? (
        <Table
            data={rows}
            columns={columns}
            onUpdateData={updateData}
            onRemoveRow={deleteRow}
            onNewRow={newRow}
            onSaveData={saveData}
            onToggleItem={toggleItem}
            onDeleteSelected={deleteSelected}
            onToggleAll={toggleAll}
            onCopyOpchainFrom={copyOpchains}
            showCopyOpchain={true}
            sampleCSV={sampleCSV}
            rowsCSV={rowsCSV}
            addCsvData={addCsvData}
            csvRowValidator={csvRowValidator}
            csvColumns={[
                'Operating Chain',
                'Rule Type',
                'Rule value',
                'PERCENTAGE/FIXED',
                'REV/GM',
                'Start Date',
                'End Date',
                'Value',
            ]}
            showDeleteAllBtn={false}
            components={{
                filter: (
                    <Filter
                        filter={filterSpec}
                        onChange={setFilterSpec}
                        operatingChainOptions={state.operatingChainOptions}
                    />
                ),
            }}
        />
    ) : (
        <div className="w-full text-center">
            <Loader />
        </div>
    );
}
