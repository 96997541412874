import React, { useState } from 'react';
import { buttons } from '@/components';
import { v4 as uuidv4 } from 'uuid';
import { Threshold } from './Threshold';

export const DefineThresholds = ({ onClose, onSave, currentThresholds }) => {
    const [thresholds, setThresholds] = useState(currentThresholds);
    const handleInputChange = (index) => (threshold) => {
        const newThresholds = thresholds.map((t, i) => {
            if (index === i) {
                return threshold;
            }
            return t;
        });
        setThresholds(newThresholds);
    };
    const handleDelete = (index) => (evt) => {
        evt.preventDefault();
        setThresholds([
            ...thresholds.slice(0, index),
            ...thresholds.slice(index + 1),
        ]);
    };
    return (
        <div className="bg-gray-700 p-6 rounded-lg text-gray-50">
            <h2 className="font-bold text-center h-10 text-xl">
                Specify actual vs budget thresholds
            </h2>
            <form className="flex flex-col space-y-2 w-full">
                <div className="w-full flex space-x-2">
                    <label className="text-gray-50 flex-1">Lower bound</label>
                    <label className="text-gray-50 flex-1">Upper bound</label>
                </div>
                {thresholds.map((threshold, index) => (
                    <Threshold
                        onChange={handleInputChange(index)}
                        key={threshold.id}
                        threshold={threshold}
                        onDelete={handleDelete(index)}
                    />
                ))}
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Add
                        onAdd={(e) => {
                            e.preventDefault();
                            setThresholds([
                                ...thresholds,
                                {
                                    id: uuidv4(),
                                    lowerBound: 0,
                                    upperBoundNotEqual: 0,
                                },
                            ]);
                        }}
                    />
                    <buttons.Save
                        onSave={(e) => {
                            e.preventDefault();
                            onSave(thresholds);
                        }}
                    />
                </div>
            </form>
        </div>
    );
};
