import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    Home,
    BonusRules,
    EmployeeFactors,
    EmployeeCorrections,
    PayoutSystems,
    HappyOrNot,
    OperationsTargets,
    Simulation,
    Competition,
    StackedBonus,
} from '@/pages';
import settings from '@/pages/settings';
import nps from '@/pages/nps';
import { constants } from '@/utils';

export default function AppRoutes() {
    return (
        <div className="flex-1 h-full overflow-auto content touch-pan-x">
            <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path='bonus-rules/general/*' element={(props) => <BonusRules {...props} mode="GENERAL" />} /> */}
                <Route
                    path="bonus-rules/date-specific/*"
                    element={<BonusRules mode={constants.DATE_SPECIFIC} />}
                />
                <Route
                    path="bonus-rules/period-specific/*"
                    element={<BonusRules mode={constants.PERIOD_SPECIFIC} />}
                />
                <Route
                    path="/bonus-rules/stacked-bonus"
                    element={<StackedBonus mode={constants.PERIOD_SPECIFIC} />}
                />
                <Route
                    path="employee-factors/*"
                    element={<EmployeeFactors />}
                />
                <Route
                    path="employee-corrections/*"
                    element={<EmployeeCorrections />}
                />
                <Route path="payout-systems/*" element={<PayoutSystems />} />
                <Route path="nps/stores/*" element={<nps.NpsStores />} />
                <Route path="nps/employees/*" element={<nps.NpsEmployees />} />
                <Route
                    path="operations-targets/*"
                    element={<OperationsTargets />}
                />
                <Route
                    path="settings/operating-chains/*"
                    element={<settings.OperatingChains />}
                />
                <Route path="settings/stores/*" element={<settings.Stores />} />
                <Route
                    path="settings/model-types/*"
                    element={<settings.ModelTypes />}
                />
                <Route
                    path="settings/negative-transactions/*"
                    element={<settings.NegativeTransactions />}
                />
                <Route path="simulation/*" element={<Simulation />} />
                <Route path="competition/*" element={<Competition />} />
            </Routes>
        </div>
    );
}
