import { memoizedMakeReqwithId, postRequest } from './apiCommon';

// prettier-ignore
const targetsApi = (axios) => ({
    getHappyOrNot: memoizedMakeReqwithId(axios, 'targets/happy-or-not'),
    saveHappyOrNot: postRequest(axios, 'targets/happy-or-not'),
    getOperationsTargets: memoizedMakeReqwithId(axios, 'targets/operations-kpis'),
    saveOperationsTargets: postRequest(axios, 'targets/operations-kpis'),
    getNpsEmployees: memoizedMakeReqwithId(axios, 'targets/nps-employees'),
    saveNpsEmployees: postRequest(axios, 'targets/nps-employees'),
});

export default targetsApi;
