/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-modal';
import { Table, buttons } from '@/components';
import {
    columnTypes,
    removeRow,
    removeAllRows,
    filters,
} from '@/components/Table/index';
import { sortStrings, validators } from '@/utils';
import { simulationIdState } from '@/simulationIdState';
import { useDependencies } from '@/DependencyProvider';
import {
    prepareTableDataMerge,
    prepareTableData,
} from './bonusRuleGeneral/common';
import Loader from '../components/Loader';

const createRow = (dataRow, storeOptions) => {
    const store = storeOptions.find((s) => s.value === dataRow.storeId);
    const row = {
        id: dataRow.storeId,
        storeId: columnTypes.storeId.cell(dataRow.storeId),
        target: columnTypes.target.cell(dataRow.target),
        storeName: columnTypes.storeName.cell(store.label),
        operatingChain: columnTypes.operatingChainForStore.cell(
            store.operatingChain
        ),
    };

    return row;
};

const AddRow = ({ onClose, onAdd, storeOptions }) => {
    const [state, setState] = useState({ storeId: '', target: 0 });
    const handleInputChange = (evt) => {
        setState({ ...state, [evt.target.name]: evt.target.value });
    };
    const setStoreId = (value) => {
        setState({ ...state, storeId: value.value });
    };
    const isValid = !!state.storeId;
    return (
        <div className="bg-gray-700 p-6 rounded-lg text-gray-50">
            <h2 className="font-bold text-center h-10 text-xl">
                Specify actual vs budget thresholds
            </h2>
            <form className="flex flex-col space-y-3 w-full">
                <div className="w-full flex space-x-2">
                    <label className="text-gray-50 flex-1">Store id</label>
                    <label className="text-gray-50 flex-1">Target</label>
                </div>
                <div className="w-full flex space-x-2 text-gray-900">
                    <Select
                        onChange={setStoreId}
                        options={storeOptions}
                        defaultInputValue={state.storeId}
                        className="pr-2 flex-1 rounded-md text-sm min-w-9"
                        menuPortalTarget={document.body}
                    />
                    <input
                        name="target"
                        type="number"
                        step="1"
                        value={state.target}
                        className="px-2 flex-1 rounded-md h-9 min-w-9 h-38px"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="space-x-2 text-right w-full">
                    <buttons.Cancel onCancel={onClose} />
                    <buttons.Save
                        enabled={isValid}
                        onSave={(e) => {
                            e.preventDefault();
                            onAdd(state);
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Filter = (props) => {
    return (
        <>
            <filters.OperatingChainFilter {...props} />
        </>
    );
};

const HappyOrNot = () => {
    const { apiFactory } = useDependencies();
    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasData, setHasData] = useState(undefined);
    const [state, setState] = useState({
        periodOptions: [],
        storeOptions: [],
        happyOrNotData: [],
    });
    const [showAddRow, setShowAddRow] = useState(false);
    const [filterSpec, setFilterSpec] = useState({
        operatingChain: { label: 'All' },
    });
    const navigate = useNavigate();
    const query = useQuery();
    const fiscalKeyQueryParam = query.get('fiscalKey') || '';
    const { masterdataApi, targetsApi, bonusSettingsApi } = apiFactory;

    const selectedFiscalKey = useMemo(() => {
        return (
            state.periodOptions
                .flatMap((o) => o.options)
                .find((fk) => fk.value === fiscalKeyQueryParam) ?? { value: '' }
        );
    }, [state, fiscalKeyQueryParam]);

    const columns = useMemo(() => {
        const fixedColumns = [
            columnTypes.storeId.column(),
            columnTypes.storeName.column(),
            columnTypes.operatingChainForStore.column(),
            columnTypes.target.column(),
        ];
        return fixedColumns;
    }, []);

    useEffect(() => {
        const data = state.happyOrNotData
            .filter((r) => r.fiscalKey === selectedFiscalKey.value)
            .flatMap((r) => r.data);
        const tableData = data.map((d) => createRow(d, state.storeOptions));
        setTableData(tableData);
    }, [state, selectedFiscalKey]);

    const copyFiscalKey = (fiscalKeyData, operatingChainData) => {
        // If no period selected, set default to All/General
        !fiscalKeyData ? (fiscalKeyData = { label: 'General', value: '' }) : '';
        const chosenOpChains = operatingChainData.map((oc) => oc.value);

        const newChosenObjects = prepareTableData(
            state.happyOrNotData,
            selectedFiscalKey.value
        ); // Table we are copying INTO
        const newCopiedObjects = prepareTableData(
            state.happyOrNotData,
            fiscalKeyData.value
        ); // Data we are copying

        let filteredNewCopiedObjects = [];
        if (operatingChainData[0]) {
            const newCopiedObjectsRows = newCopiedObjects.map((obj) =>
                createRow(obj, state.storeOptions)
            );
            const newCopiedObjectsRowsFilteredByOpChain =
                newCopiedObjectsRows.filter((row) =>
                    chosenOpChains.includes(row.operatingChain.value)
                );
            filteredNewCopiedObjects =
                newCopiedObjectsRowsFilteredByOpChain.map((row) => {
                    return { storeId: row.id, target: row.target.value };
                });
        } else {
            filteredNewCopiedObjects = newCopiedObjects;
        }

        const mergedPeriods = prepareTableDataMerge(
            newChosenObjects,
            filteredNewCopiedObjects
        );

        const tableData = mergedPeriods.map((mp) =>
            createRow(mp, state.storeOptions)
        );
        setTableData(tableData);
    };

    useEffect(() => {
        let mounted = true;
        async function getData() {
            const [periodOptions, stores, happyOrNotData, operatingChains] =
                await Promise.all([
                    masterdataApi.getBonusPeriods({ includeEmpty: true }),
                    masterdataApi.getStores(),
                    targetsApi.getHappyOrNot(),
                    bonusSettingsApi.getOperatingChains(),
                ]);
            const storeOptions = stores
                .map((store) => ({
                    value: store.storeId,
                    label: `${store.departmentName} (${store.storeId})`,
                    operatingChain: store.operatingChain,
                }))
                .sort((a, b) => sortStrings(a.label, b.label));
            const operatingChainOptions = operatingChains.map((op) => ({
                label: op.description,
                value: op.operatingChain,
            }));
            mounted
                ? setState({
                      happyOrNotData,
                      periodOptions,
                      storeOptions,
                      operatingChainOptions,
                  })
                : '';
            setHasData(
                happyOrNotData && happyOrNotData.length > 0 ? true : false
            );
        }
        getData();
        return () => (mounted = false);
    }, [masterdataApi, targetsApi, bonusSettingsApi]);

    useEffect(() => {
        if ((hasData && tableData.length > 0) || hasData === false) {
            setIsLoaded(true);
        }
    }, [hasData, tableData]);

    const saveData = async () => {
        const transform = (tableData) => {
            const data = tableData.map((row) => {
                return {
                    storeId: columnTypes.storeId.value(row),
                    target: columnTypes.target.value(row),
                };
            });
            return {
                data: data,
                fiscalKey: selectedFiscalKey.value,
                simulationId: simulationIdState.get()[0].simulationId,
            };
        };
        const data = transform(tableData);
        await targetsApi.saveHappyOrNot(data);
        const updatedData = await targetsApi.getHappyOrNot(true);
        setState({ ...state, happyOrNotData: updatedData });
        return {
            valid: true,
        };
    };

    const updateData = (id, columnName, value) => {
        const newTableData = [...tableData];
        const rowIndex = newTableData.findIndex((y) => y.id == id);
        const row = newTableData[rowIndex];
        row[columnName] = { ...row[columnName], ...{ value } };
        setTableData(newTableData);
    };

    const addCsvData = (rows) => {
        const newRows = rows.data.map((row) => ({
            id: row.items[0],
            storeId: row.items[0],
            target: row.items[1],
        }));
        const updatedData = tableData.map((row) => {
            const newRow = newRows.find((y) => y.storeId === row.storeId.value);
            return newRow ? createRow(newRow, state.storeOptions) : row;
        });
        const filteredRows = newRows.filter(
            (row) =>
                updatedData.findIndex(
                    (uRow) => row.storeId === uRow.storeId.value
                ) < 0
        );
        const newTableData = [
            ...updatedData,
            ...filteredRows.map((d) => createRow(d, state.storeOptions)),
        ];
        setTableData(newTableData);
    };

    const periodChanged = (selectedValue) => {
        navigate({
            search: `?fiscalKey=${selectedValue.value}`,
        });
    };

    const deleteRow = (id) => setTableData(removeRow(id, tableData));

    const deleteAllRows = () => {
        setTableData(removeAllRows(tableData, filterSpec.operatingChain));
    };

    const openAddRowModal = () => setShowAddRow(true);
    const closeAddRowModal = () => setShowAddRow(false);
    const addRow = (data) => {
        closeAddRowModal();
        setTableData([...tableData, createRow(data, state.storeOptions)]);
    };

    const existValidator = (message, currentData) => async (row) => {
        const storeId = row[0];
        const exists =
            currentData.findIndex((d) => d.storeId.value === storeId) >= 0;
        return {
            isValid: true,
            text: exists ? message : '',
        };
    };

    const { optionValidator, composeValidators } = validators;
    const csvRowValidator = (currentData, storeOptions) => async (row) => {
        const validators = [
            optionValidator('Invalid store', storeOptions, 0),
            existValidator('Exists - will update', currentData),
        ];
        const validator = composeValidators(validators);
        return await validator(row);
    };

    const rows = useMemo(() => {
        const filter = filters.composeFilters([filters.operatingChain]);
        const filtered = tableData.filter(filter(filterSpec));
        return [...filtered];
    }, [filterSpec, tableData]);

    const sampleCSV = () => `3072\t91
3030\t92
    `;

    // Convert table data to string
    const rowsCSV = useMemo(() => {
        if (rows.length > 0) {
            const rowStrings = rows.map(
                (row) =>
                    `${row.storeId.value}\t${row.storeName.value}\t${row.operatingChain.value}\t${row.target.value}`
            );
            return rowStrings.join('\n');
        }
    }, [rows]);

    return (
        <div className="flex w-full h-full flex-col">
            <div className="text-center py-8 w-full">
                <label className="font-bold">Period: </label>
                <Select
                    options={state.periodOptions}
                    onChange={periodChanged}
                    className="w-48 inline-block text-gray-900"
                    value={selectedFiscalKey}
                />
            </div>
            <div className="content w-full flex-1 flex flex-row">
                {isLoaded ? (
                    <Table
                        data={rows}
                        onNewRow={openAddRowModal}
                        columns={columns}
                        onSaveData={saveData}
                        onUpdateData={updateData}
                        csvRowValidator={csvRowValidator(
                            tableData,
                            state.storeOptions
                        )}
                        onRemoveRow={deleteRow}
                        onRemoveAllRows={deleteAllRows}
                        addCsvData={addCsvData}
                        csvColumns={['Store id', 'Target']}
                        components={{
                            filter: (
                                <Filter
                                    filter={filterSpec}
                                    onChange={setFilterSpec}
                                    operatingChainOptions={
                                        state.operatingChainOptions
                                    }
                                />
                            ),
                        }}
                        sampleCSV={sampleCSV}
                        rowsCSV={rowsCSV}
                        copyFiscalKey={copyFiscalKey}
                        showCopyFiscal={true}
                        onCopyFrom={copyFiscalKey}
                        filterSpec={filterSpec}
                        fiscalKey={fiscalKeyQueryParam}
                    />
                ) : (
                    <div className="w-full text-center">
                        <Loader />
                    </div>
                )}
            </div>
            <Modal
                isOpen={showAddRow}
                className="bg-gray-700 w-full max-w-5xl h-auto rounded-lg"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-0 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowAddRow(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <AddRow
                    onClose={closeAddRowModal}
                    onAdd={addRow}
                    storeOptions={state.storeOptions}
                />
            </Modal>
        </div>
    );
};

export default HappyOrNot;
