import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import Modal from 'react-modal';
import { buttons, CSVForm } from '@/components';
import { DefineThresholds } from './DefineTresholds';

export const PayoutSystemsAction4Multiplier = (props) => {
    const { thresholds, saveColumns, setChangedSavedState, handleSave } = props;
    const [showAddColumnModal, setShowAddColumnModal] = useState(false);
    const [showCSVForm, setShowCSVForm] = useState(false);
    const [validateRowNumber, setValidateRowNumber] = useState(null);

    const onSave = (state) => {
        setShowCSVForm(false);
        handleSave(state);
    };

    const onSaveColumns = (state) => {
        setShowAddColumnModal(false);
        saveColumns(state);
        setChangedSavedState(true);
    };

    const sampleCSV = () => {
        return `0 - 90\t90 - 100\t100 - 110\t110 - 999
10\t20\t30\t40`;
    };

    const csvColumnsValidator = (value) => {
        setValidateRowNumber(value.length);
    };

    const validateRow = (row) => {
        const isNumeric = (num) =>
            (typeof num === 'number' ||
                (typeof num === 'string' && num.trim() !== '' && num !== '')) &&
            !isNaN(num);

        const allNumbers = row.every(isNumeric);

        if (validateRowNumber !== row.length) {
            return {
                isValid: false,
                text: ['All entries must be filled.'],
            };
        }

        if (allNumbers) {
            return {
                isValid: true,
                text: ['OK'],
            };
        } else {
            return {
                isValid: false,
                text: ['All entries must be numbers.'],
            };
        }
    };

    const downloadCSV = (rowsCSV) => {
        if (rowsCSV) {
            const data = rowsCSV.split('\n').map((r) => r.split('\t'));
            const worksheet = XLSX.utils.aoa_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Table');
            XLSX.writeFile(workbook, 'table.xlsx');
        }
    };

    return (
        <>
            <buttons.AddColumn
                text="Threshold"
                onAdd={() => setShowAddColumnModal(true)}
            />
            <buttons.AddCsv onAdd={() => setShowCSVForm(!showCSVForm)} />
            <buttons.SaveAsCSV
                onSave={(e) => {
                    e.preventDefault();
                    downloadCSV(props.rowsCSV);
                }}
            />
            <Modal
                isOpen={showCSVForm}
                className="bg-gray-700 w-full max-w-5xl h-auto rounded-lg"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-0 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowCSVForm(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <CSVForm
                    hasHeader
                    onClose={() => setShowCSVForm(false)}
                    onSave={onSave}
                    sampleCSV={sampleCSV}
                    validateRow={validateRow}
                    csvColumnsValidator={csvColumnsValidator}
                />
            </Modal>
            <Modal
                isOpen={showAddColumnModal}
                className="bg-gray-700 w-full max-w-5xl h-auto rounded-lg"
                overlayClassName="flex justify-center items-center fixed top-0 right-0 left-0 bottom-0 bg-gray-900 bg-opacity-80"
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setShowAddColumnModal(false);
                }}
                shouldCloseOnOverlayClick={true}
            >
                <DefineThresholds
                    onSave={onSaveColumns}
                    onClose={() => setShowAddColumnModal(false)}
                    currentThresholds={thresholds}
                />
            </Modal>
        </>
    );
};
